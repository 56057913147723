import React, { useEffect, useRef } from 'react';
import './chart.css';
import Chart from 'chart.js/auto';

export default function ChartComponent({ title, categoryOrderData }) {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current && categoryOrderData && categoryOrderData.length > 0) {
            const ctx = canvasRef.current.getContext('2d');

            if (chartRef.current) {
                chartRef.current.destroy();
            }

            const labels = categoryOrderData.map((category) => category.category);
            const data = categoryOrderData.map((category) => category.percentage);

            const newChart = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        hoverOffset: 4,
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            });

            chartRef.current = newChart;
        }
    }, [categoryOrderData]);

    return (
        <div className="chart">
            <div className="title">{title}</div>
            <canvas id="pieChart" ref={canvasRef} style={{ maxHeight: '400px' }}></canvas>
        </div>
    );
}
