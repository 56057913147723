import React, { useEffect, useState, useCallback } from 'react';
import './style.css';
import { DataGrid } from "@mui/x-data-grid";
import { query, collection, where, getDocs } from "firebase/firestore";
import { auth, db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar'


export default function PastOrder() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = useCallback(async (uid) => {
        const ordersRef = collection(db, 'Orders');
        const ordersQuery = query(ordersRef, where('shopId', '==', uid), where('status', 'in', ['Delivered', 'Cancelled']));

        try {
            setIsLoading(true);

            const querySnapshot = await getDocs(ordersQuery);

            const ordersData = querySnapshot.docs.map((doc) => {
                const order = doc.data();

                return {
                    id: doc.id,
                    ...order,
                };
            });

            ordersData.sort((a, b) => b.timestamp - a.timestamp);
            setData(ordersData);
        } catch (error) {
            toast.error('Error in Fetching the Orders History. Please Contact the Administrator!!');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged((user) => {
            if (user) {
                fetchData(user.uid);
            } else {
                setData([]);
                setIsLoading(false);
            }
        });

        return () => {
            unsubscribeAuth();
        };
    }, [fetchData]);

    const columns = [
        {
            field: 'id',
            headerName: 'Order ID',
            width: 200,
            renderCell: (params) => {
                const orderId = params.value;
                return (
                    <div
                        style={{ cursor: 'pointer', color: '#1976D2' }}
                        data-bs-toggle="modal"
                        data-bs-target={`#bootstrapModal${orderId}`}
                    >
                        {orderId}
                    </div>
                );
            },
        },

        { field: 'paymentId', headerName: 'Payment ID', flex: 1, minWidth: 180, maxWidth: 200 },

        {
            field: 'userName', headerName: 'User Name', width: 120, renderCell: (params) => {
                const userName = params.value;
                return <div>{userName}</div>;
            }
        },

        {
            field: 'totalAmount',
            headerName: 'Amount',
            width: 70,
            renderCell: (params) => {
                const amount = params.value;
                const shopDeliveryCut = params.row.shopDeliveryCut;
                const packingFee = params.row.packingFee;
                const isGST = params.row.isGST;
                const itemSubtotal = params.row.itemSubtotal;
                let totalAmount = itemSubtotal;
                if (shopDeliveryCut && parseFloat(shopDeliveryCut) > 0) {
                    totalAmount += parseFloat(shopDeliveryCut);
                }
                if (packingFee && parseFloat(packingFee) > 0) {
                    totalAmount += parseFloat(packingFee);
                }
                if (isGST) {
                    totalAmount += 0.05 * itemSubtotal;
                }
                return <div>&#8377;{totalAmount}</div>;
            }
        },

        {
            field: 'status',
            headerName: 'Order Status',
            width: 100,
            renderCell: (params) => {
                const orderStatus = params.value;
                return <div>{orderStatus}</div>;
            }
        },

        {
            field: 'timestamp',
            headerName: 'Order Placed Time',
            width: 180,
            renderCell: (params) => {
                const timestamp = params.value || { seconds: 0 };
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp.seconds * 1000));
                return <div>{formattedTimestamp}</div>;
            }
        },

        {
            field: 'updatedAt',
            headerName: 'Delivered Time',
            width: 200,
            renderCell: (params) => {
                const timestamp = params.value || { seconds: 0 };
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp.seconds * 1000));
                return <div>{formattedTimestamp}</div>;
            }
        },
    ];
    return (
        <>
            {data.map((order) => (
                <div className="modal fade" id={`bootstrapModal${order.id}`} tabIndex="-1" aria-labelledby={`bootstrapModalLabel${order.id}`} aria-hidden="true" key={`bootstrap${order.id}`}>
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ borderRadius: '15px', borderTop: '3px solid #f37a27', borderBottom: '3px solid #f37a27' }}>
                            <div className="modal-header" style={{ paddingTop: '10px' }}>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start text-black pt-0 pb-0">
                                <h5 className="modal-title text-uppercase text-center mb-1" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>{order.shopName}</h5>
                                <h6 className="modal-title text-center mb-0" id="bootstrapModal" style={{ fontWeight: 'lighter' }}>GSTIN: {order.BusinessGSTIN}</h6>
                                <h6 className="modal-title text-center mb-0" id="bootstrapModal" style={{ fontWeight: 'lighter' }}>Phone No: +91 {order.shopPhone}</h6>
                                <hr />
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order ID</p>
                                        <p>{order.id}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Payment ID</p>
                                        <p>{order.paymentId}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">User Name</p>
                                        <p>{order.userName}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Status</p>
                                        <p>{order.status}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    {order.OrderType === 'Delivery' ? (<div className="col mb-1">
                                        <p className="small text-muted mb-1">Delivery Address</p>
                                        <p className="mb-0">{order.userAddress}</p>
                                        <p>{order.userCampus}</p>
                                    </div>) : (<div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Type</p>
                                        <p>{order.OrderType}</p>
                                    </div>)}
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Phone Number</p>
                                        <p>+91 {order.userPhone}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Placed Time</p>
                                        <p>{new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        }).format(new Date(order.timestamp.seconds * 1000))}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Delivered Time</p>
                                        <p>{new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        }).format(new Date(order.updatedAt.seconds * 1000))}</p>
                                    </div>
                                </div>
                                {order.orderInstruction ? (<div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Instruction</p>
                                        <p>{order.orderInstruction}</p>
                                    </div>
                                </div>) : null}
                                <h4 className="mt-2 mb-2" style={{ color: "#35558a" }}>Payment Summary</h4>
                                <hr className="mt-2 mb-4" style={{ height: "0", backgroundColor: "transparent", opacity: ".75", bordertop: "2px dashed #9e9e9e" }} />
                                <div>
                                    {order.products && order.products.map(product => (
                                        <div className="d-flex justify-content-between" key={product.productId}>
                                            <div>
                                                <p className="fw-bold mb-0">{product.name} (Qty: {product.quantity})</p>
                                                <h6>{product.subtitle}</h6>
                                            </div>
                                            <p className="mb-0">&#8377;{product.price * product.quantity}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex justify-content-between mt-2">
                                    <h6 className="fw-bold">Sub Total</h6>
                                    <p className="lead fw-bold mb-0" style={{ fontWeight: 'bold', fontSize: '18px' }}>&#8377;{order.itemSubtotal.toFixed(2)}</p>
                                </div>
                                <hr className="mt-1" />

                                {parseFloat(order.shopDeliveryCut) > 0 ? (
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0">Delivery Charges</p>
                                        <p className="mb-0">&#8377;{order.shopDeliveryCut}</p>
                                    </div>) : null}

                                {parseFloat(order.packingFee) > 0 ? (
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0">Packing Charges</p>
                                        <p className="mb-0">&#8377;{order.packingFee}</p>
                                    </div>) : null}

                                {order.isGST ? (
                                    <>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">CGST (2.5%)</p>
                                            <p className="mb-0">&#8377;{(0.025 * order.itemSubtotal).toFixed(2)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">SGST (2.5%)</p>
                                            <p className="mb-0">&#8377;{(0.025 * order.itemSubtotal).toFixed(2)}</p>
                                        </div>
                                    </>) : null}

                                <div className="d-flex justify-content-between mt-2">
                                    <h5 className="fw-bold">Grand Total</h5>
                                    <p className="lead fw-bold mb-0" style={{ color: "#f37a27" }}>&#8377;{(order.itemSubtotal + (order.shopDeliveryCut ? parseFloat(order.shopDeliveryCut) : 0) + (order.isGST ? 0.05 * order.itemSubtotal : 0)).toFixed(2)}</p>
                                </div>
                                <hr className="mt-1" />
                                <h6 className="modal-title text-center mb-1" id="bootstrapModal">FSSAI License Number: {order.FSSAI}</h6>
                                <h5 className="modal-title text-uppercase text-center mb-0" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>Thank You!!</h5>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="home">
                <Navbar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <div className="datatable">
                            <ToastContainer
                                position="top-right"
                                autoClose={2400}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" />
                            {isLoading && <Loader />}
                            <div className="datatableTitle">
                                Past Orders
                            </div>
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 10, page: 0 },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50]}
                                    getRowHeight={({ id, densityFactor }) => {
                                        return 60 * densityFactor;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
