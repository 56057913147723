import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { query, collection, where, getDocs, getDoc, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { auth, db } from "../../Config/Config";
import Navbar from './Navbar'
import './style.css';
import { DataGrid } from "@mui/x-data-grid";
import Loader from '../Loader/Loader';


export default function OwnerRefund() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [config, setConfig] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const uid = currentUser.uid;
                    const userRef = doc(db, 'businessUsers', uid);
                    const docSnapshot = await getDoc(userRef);
                    const userData = docSnapshot.data();
                    const refundOrders = userData.refundOrders || [];

                    if (refundOrders.length === 0) {
                        setData([]);
                        setIsLoading(false);
                        return;
                    }

                    const configRef = doc(db, 'globals', 'businessApp');
                    const configSnapshot = await getDoc(configRef);
                    const configData = configSnapshot.data();
                    setConfig(configData);

                    const ordersRef = collection(db, 'Orders');
                    const ordersQuery = query(ordersRef, where('__name__', 'in', refundOrders));

                    try {
                        setIsLoading(true);

                        const querySnapshot = await getDocs(ordersQuery);

                        const ordersData = querySnapshot.docs.map((doc) => {
                            const order = doc.data();
                            return {
                                id: doc.id,
                                ...order,
                            };
                        });

                        ordersData.sort((a, b) => b.timestamp - a.timestamp);
                        setData(ordersData);
                    } catch (error) {
                        toast.error('Error in Fetching the Orders History. Please Contact the Administrator!!');
                    } finally {
                        setIsLoading(false);
                    }
                }
            } catch (error) {
                toast.error(error.message);
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const capturePayment = async (paymentId, paisa, url) => {
        try {
            url = `${url}payment_id=${paymentId}&amt=${paisa}`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({})
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 'captured') {
                    return true;
                }
            }
        } catch (e) {
            console.error(e);
            toast.error("Error capturing payment. Please Check your Internet Connection.");
            return false;
        }
    };

    const refundPayment = async (paymentId, paisa, url) => {
        try {
            url = `${url}amt=${paisa}&payment_id=${paymentId}`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({})
            });

            if (response.ok) {
                const data = await response.json();
                return data.refundID || '';
            }
        } catch (e) {
            console.error(e);
            toast.error("Error refunding payment. Please Check your Internet Connection.");
            return '';
        }
    };

    const refundOrderAmount = async (orderId,
        paymentId = '',
        paisa = 0,
        isTest = false) => {
        try {
            const orderRef = doc(db, 'Orders', orderId);
            let refundID = '';
            if (!isTest) {
                const status = await capturePayment(paymentId, paisa, config.apiLinkCapture);
                if (!status) return false;

                refundID = await refundPayment(paymentId, paisa, config.apiLinkRefund);
                if (!refundID) return false;
            }
            await updateDoc(orderRef, {
                'updatedAt': serverTimestamp(),
                'refundId': refundID,
                'refundApproved': true
            });

            const currentUser = auth.currentUser;
            if (currentUser) {
                const uid = currentUser.uid;
                const shopRef = doc(db, 'businessUsers', uid);
                const docSnapshot = await getDoc(shopRef);
                const shopData = docSnapshot.data();

                const refundOrders = shopData.refundOrders || [];
                const updatedRefundOrders = refundOrders.filter(order => order !== orderId);

                await updateDoc(shopRef, {
                    refundOrders: updatedRefundOrders
                });
            }
        } catch (e) {
            console.error(e);
            toast.error("Error updating refund status. Please try again later!!");
            return false;
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Order ID',
            width: 200,
            renderCell: (params) => {
                const orderId = params.value;
                return (
                    <div
                        style={{ cursor: 'pointer', color: '#1976D2' }}
                        data-bs-toggle="modal"
                        data-bs-target={`#bootstrapModal${orderId}`}
                    >
                        {orderId}
                    </div>
                );
            },
        },

        { field: 'paymentId', headerName: 'Payment ID', flex: 1, minWidth: 180, maxWidth: 200 },

        {
            field: 'userName', headerName: 'User Name', width: 120, renderCell: (params) => {
                const userName = params.value;
                return <div>{userName}</div>;
            }
        },

        {
            field: 'totalAmount',
            headerName: 'Amount',
            width: 70,
            renderCell: (params) => {
                const amount = params.value;
                const shopDeliveryCut = params.row.shopDeliveryCut;

                let totalAmount = params.row.itemSubtotal;
                if (shopDeliveryCut > 0) {
                    totalAmount += parseFloat(shopDeliveryCut);
                }

                return <div>&#8377;{totalAmount}</div>;
            }
        },

        {
            field: 'timestamp',
            headerName: 'Order Placed Time',
            width: 180,
            renderCell: (params) => {
                const timestamp = params.value || { seconds: 0 };
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp.seconds * 1000));
                return <div>{formattedTimestamp}</div>;
            }
        },

        {
            field: 'updatedAt',
            headerName: 'Delivered Time',
            width: 200,
            renderCell: (params) => {
                const timestamp = params.value || { seconds: 0 };
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp.seconds * 1000));
                return <div>{formattedTimestamp}</div>;
            }
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                const refundAmount = parseFloat(params.row.totalAmount);
                const orderId = params.row.id;
                const paymentId = params.row.paymentId;
                const isTest = params.row.isTest;

                return (
                    <div className="cellAction">
                        <div
                            className="deleteButton effect effect-2 mb-1"
                            onClick={() => refundOrderAmount(orderId, paymentId, refundAmount * 100, isTest)}
                        >
                            Refund
                        </div>
                    </div>
                );
            },
        }
    ];
    return (
        <>
            {data.map((order) => (
                <div className="modal fade" id={`bootstrapModal${order.id}`} tabIndex="-1" aria-labelledby={`bootstrapModalLabel${order.id}`} aria-hidden="true" key={`bootstrap${order.id}`}>
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ borderRadius: '15px', borderTop: '3px solid #f37a27', borderBottom: '3px solid #f37a27' }}>
                            <div className="modal-header" style={{ paddingTop: '10px' }}>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start text-black pt-0 pb-0">
                                <h5 className="modal-title text-uppercase text-center mb-1" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>{order.shopName}</h5>
                                <h6 className="modal-title text-center mb-0" id="bootstrapModal" style={{ fontWeight: 'lighter' }}>GSTIN: {order.BusinessGSTIN}</h6>
                                <h6 className="modal-title text-center mb-0" id="bootstrapModal" style={{ fontWeight: 'lighter' }}>Phone No: +91 {order.shopPhone}</h6>
                                <hr />
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order ID</p>
                                        <p>{order.id}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Payment ID</p>
                                        <p>{order.paymentId}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">User Name</p>
                                        <p>{order.userName}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Status</p>
                                        <p>{order.status}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    {order.OrderType === 'Delivery' ? (<div className="col mb-1">
                                        <p className="small text-muted mb-1">Delivery Address</p>
                                        <p className="mb-0">{order.userAddress}</p>
                                        <p>{order.userCampus}</p>
                                    </div>) : (<div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Type</p>
                                        <p>{order.OrderType}</p>
                                    </div>)}
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Phone Number</p>
                                        <p>+91 {order.userPhone}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Placed Time</p>
                                        <p>{new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        }).format(new Date(order.timestamp.seconds * 1000))}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Delivered Time</p>
                                        <p>{new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        }).format(new Date(order.updatedAt.seconds * 1000))}</p>
                                    </div>
                                </div>
                                {order.orderInstruction ? (<div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Instruction</p>
                                        <p>{order.orderInstruction}</p>
                                    </div>
                                </div>) : null}
                                <h4 className="mt-2 mb-2" style={{ color: "#35558a" }}>Payment Summary</h4>
                                <hr className="mt-2 mb-4" style={{ height: "0", backgroundColor: "transparent", opacity: ".75", bordertop: "2px dashed #9e9e9e" }} />
                                <div>
                                    {order.products && order.products.map(product => (
                                        <div className="d-flex justify-content-between" key={product.productId}>
                                            <p className="mb-0">{product.name} (Qty: {product.quantity})</p>
                                            <p className="mb-0">&#8377;{product.price * product.quantity}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className="d-flex justify-content-between mt-2">
                                    <h6 className="fw-bold">Sub Total</h6>
                                    <p className="lead fw-bold mb-0" style={{ fontWeight: 'bold', fontSize: '18px' }}>&#8377;{order.itemSubtotal.toFixed(2)}</p>
                                </div>
                                <hr className="mt-1" />

                                {parseFloat(order.shopDeliveryCut) > 0 ? (
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0">Delivery Charges</p>
                                        <p className="mb-0">&#8377;{order.shopDeliveryCut}</p>
                                    </div>) : null}

                                {order.isGST ? (
                                    <>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">CGST (2.5%)</p>
                                            <p className="mb-0">&#8377;{(0.025 * order.itemSubtotal).toFixed(2)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">SGST (2.5%)</p>
                                            <p className="mb-0">&#8377;{(0.025 * order.itemSubtotal).toFixed(2)}</p>
                                        </div>
                                    </>) : null}

                                <div className="d-flex justify-content-between mt-2">
                                    <h5 className="fw-bold">Grand Total</h5>
                                    <p className="lead fw-bold mb-0" style={{ color: "#f37a27" }}>&#8377;{(order.itemSubtotal + (order.shopDeliveryCut ? parseFloat(order.shopDeliveryCut) : 0) + (order.isGST ? 0.05 * order.itemSubtotal : 0)).toFixed(2)}</p>
                                </div>
                                <hr className="mt-1" />
                                <h6 className="modal-title text-center mb-1" id="bootstrapModal">FSSAI License Number: {order.FSSAI}</h6>
                                <h5 className="modal-title text-uppercase text-center mb-0" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>Thank You!!</h5>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="home">
                <Navbar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <div className="datatable">
                            <ToastContainer
                                position="top-right"
                                autoClose={2400}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" />
                            {isLoading && <Loader />}
                            <div className="datatableTitle">
                                Refund Orders
                            </div>
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 10, page: 0 },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50]}
                                    getRowHeight={({ id, densityFactor }) => {
                                        return 60 * densityFactor;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
