import React from 'react'
import "./featured.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

export default function Featured({ totalTodayRevenue, totalLastDayRevenue, totalLastWeekRevenue, totalLastMonthRevenue, todayCancelledOrders, todayDeliveredOrders }) {
    const isRevenueIncreased = (current, previous) => current > previous;
    const todaySalesPercentage = todayDeliveredOrders / (todayCancelledOrders + todayDeliveredOrders) * 100;
    return (
        <>
            <div className="featured">
                <div className="top">
                    <h1 className="title">Total Revenue</h1>
                    <MoreVertIcon fontSize="small" />
                </div>
                <div className="bottom">
                    <div className="featuredChart">
                        <CircularProgressbar
                            value={isNaN(todaySalesPercentage) ? 0 : todaySalesPercentage}
                            text={`${isNaN(todaySalesPercentage) ? '0.00%' : `${todaySalesPercentage.toFixed(2)}%`}`}
                            strokeWidth={5}
                        />

                    </div>
                    <p className="title">Total sales made today</p>
                    <p className="amount">₹{totalTodayRevenue}</p>
                    <p className="desc">
                        Previous transactions processing. Last payments may not be included.
                    </p>
                    <div className="summary">
                        <div className="item">
                            <div className="itemTitle">Last Day</div>
                            <div className={`itemResult ${isRevenueIncreased(totalTodayRevenue, totalLastDayRevenue) ? 'positive' : 'negative'}`}>
                                {isRevenueIncreased(totalTodayRevenue, totalLastDayRevenue) ? <KeyboardArrowUpOutlinedIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                                <div className="resultAmount">₹{totalLastDayRevenue}</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="itemTitle">Last Week</div>
                            <div className={`itemResult ${isRevenueIncreased(totalTodayRevenue, totalLastWeekRevenue / 7) ? 'positive' : 'negative'}`}>
                                {isRevenueIncreased(totalTodayRevenue, totalLastWeekRevenue) ? <KeyboardArrowUpOutlinedIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                                <div className="resultAmount">₹{totalLastWeekRevenue}</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="itemTitle">Last Month</div>
                            <div className={`itemResult ${isRevenueIncreased(totalTodayRevenue, totalLastMonthRevenue / 30) ? 'positive' : 'negative'}`}>
                                {isRevenueIncreased(totalTodayRevenue, totalLastMonthRevenue / 30) ? <KeyboardArrowUpOutlinedIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
                                <div className="resultAmount">₹{totalLastMonthRevenue}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
