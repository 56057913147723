import React, { useEffect, useState } from 'react';
import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from "@mui/x-data-grid";
import '../Shops/style.css';
import Sidebar from './Sidebar/Sidebar';
import { Link } from "react-router-dom";



export default function BusinessUsersList() {

    const [businessUsers, setBusinessUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleDelete = async (id) => {
        setIsLoading(true);
        try {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const userRef = doc(db, 'businessUsers', id);
                await deleteDoc(userRef)
                    .then(() => {
                        toast.success("Document deleted successfully from Firestore.");
                    })
                    .catch((error) => {
                        toast.error("Error deleting document from Firestore. Please check the console for details.");
                    });
            }
        } catch (err) {
            toast.error(err.message);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const unsubscribeAuthStateChange = auth.onAuthStateChanged((user) => {
            if (user) {
                const currentUser = auth.currentUser;

                if (!currentUser) {
                    return;
                }

                setIsLoading(true);
                const businessUsersCollectionRef = collection(db, 'businessUsers');

                const unsubscribe = onSnapshot(businessUsersCollectionRef, (querySnapshot) => {
                    const usersData = [];
                    querySnapshot.forEach((doc) => {
                        const userFirstName = doc.data()?.FirstName || '';
                        const userLastName = doc.data()?.LastName || '';
                        const userFullName = userFirstName + ' ' + userLastName;

                        usersData.push({
                            id: doc.id,
                            ...doc.data(),
                            userFullName: userFullName,
                        });
                    });
                    setBusinessUsers(usersData);
                    setIsLoading(false);
                });

                return () => {
                    unsubscribe();
                };
            } else {
                setIsLoading(false);
                setBusinessUsers([]);
            }
        });

        return () => {
            unsubscribeAuthStateChange();
        };
    }, []);


    const columns = [
        {
            field: 'id',
            headerName: 'Business User ID',
            width: 270,
            renderCell: (params) => {
                const businessUserId = params.value;
                return (
                    <div>
                        <Link
                            to={`/admin/businessUsers/${businessUserId}`}
                            style={{
                                color: '#007bff',
                                transition: 'color 0.3s',
                            }}
                            onMouseEnter={(e) => e.target.style.color = '#0056b3'}  // Change color on hover
                            onMouseLeave={(e) => e.target.style.color = '#007bff'}  // Revert color on mouse leave
                        >
                            {businessUserId}
                        </Link>


                    </div>
                );
            },
        },

        {
            field: 'BusinessGSTIN',
            headerName: 'GSTIN',
            width: 100,
            renderCell: (params) => {
                const businessGSTIN = params.value;
                return <div>{businessGSTIN}</div>;
            }
        },

        {
            field: 'BusinessName',
            headerName: 'Business Name',
            width: 170,
            renderCell: (params) => {
                const businessName = params.value;
                return <div>{businessName}</div>;
            }
        },

        {
            field: 'ServiceAvailable',
            headerName: 'Service Available',
            width: 150,
            renderCell: (params) => {
                const services = params.value || [];
                return (
                    <div>
                        {services.map((service) => (
                            <div key={service}>{service}</div>
                        ))}
                    </div>
                );
            }
        },

        {
            field: 'BusinessEmail',
            headerName: 'Business Email',
            width: 200,
            renderCell: (params) => {
                const businessEmail = params.value;
                return <div>{businessEmail}</div>;
            }
        },

        { field: 'userFullName', headerName: 'Owner Name', width: 150 },

        {
            field: 'Phone', headerName: 'Shop Contact', width: 130, renderCell: (params) => {
                const phoneNumber = params.value || '';
                const formattedPhoneNumber = `+91 ${phoneNumber}`;
                return <div>{formattedPhoneNumber}</div>;
            }
        },

        {
            field: 'ShopStatus',
            headerName: 'Shop Status',
            width: 100,
            renderCell: (params) => {
                const shopStatus = params.value;
                return <div>{shopStatus}</div>;
            }
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div
                            className="deleteButton effect effect-2"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className="home">
                <Sidebar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <div className="datatable">
                            <ToastContainer
                                position="top-right"
                                autoClose={2400}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" />
                            {isLoading && <Loader />}
                            <div className="datatableTitle">
                                Business Users
                            </div>
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={businessUsers}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 10, page: 0 },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50]}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    getRowHeight={({ id, densityFactor }) => {
                                        return 85 * densityFactor;
                                    }}
                                    autosizeOptions={{
                                        columns: ['BusinessEmail', 'BusinessGSTIN'],
                                        includeOutliers: true,
                                        includeHeaders: false,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
