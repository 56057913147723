import React, { useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import BusinessRegister from "./BusinessRegister";
import UserRegister from "./UserRegister";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Register() {
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState("user");

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    navigate(`/register?role=${role}`);
  };

  return (
    <>
      <section className="background-radial-gradient overflow-hidden" data-aos="fade-up" data-aos-delay="150">
        <ToastContainer
          position="top-right"
          autoClose={2400}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light" />
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-3">
          <div className="row gx-lg-5 align-items-top mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: "10", paddingTop: '200px' }}>
              <h1
                className="my-5 display-5 fw-bold ls-tight"
                style={{ color: "hsl(218, 81%, 95%)" }}
              >
                The best offer <br />
                <span style={{ color: "hsl(218, 81%, 75%)" }}>
                  for your business
                </span>
              </h1>
              <p
                className="mb-4 opacity-70"
                style={{ color: "hsl(218, 81%, 85%)" }}
              >
                Welcome to Cravee Jodhpur, your one-stop destination for local food businesses to showcase and sell their delectable products. Discover a wide range of culinary delights right at your doorstep, making it convenient for residents to savor the flavors of Jodhpur's finest offerings!</p>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div
                id="radius-shape-1"
                className="position-absolute rounded-circle shadow-5-strong"
              ></div>
              <div
                id="radius-shape-2"
                className="position-absolute shadow-5-strong"
              ></div>

              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <div className="role-partition">
                    <div
                      className={`role-option ${selectedRole === "user" ? "active" : ""
                        }`}
                      onClick={() => handleRoleClick("user")}
                    >
                      <h3>User</h3>
                    </div>
                    <div
                      className={`role-option ${selectedRole === "business" ? "active" : ""
                        }`}
                      onClick={() => handleRoleClick("business")}
                    >
                      <h3>Business</h3>
                    </div>
                  </div>

                  {selectedRole === "user" ? (
                    <UserRegister />
                  ) : (
                    <BusinessRegister />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
