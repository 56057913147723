import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../Config/Config';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar/Sidebar';

export default function BusinessQuery() {

    const { queryUserId } = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRef = doc(db, 'QueryBusinessUsers', queryUserId);
                const unsub = onSnapshot(
                    userRef,
                    (docSnapshot) => {
                        if (docSnapshot.exists()) {
                            const userData = docSnapshot.data();
                            setData(userData);
                            setIsLoading(false);
                        } else {
                            setData([]);
                        }
                        setIsLoading(false);
                    },
                    (error) => {
                        toast.error(error.message);
                        setIsLoading(false);
                    }
                );
                return () => {
                    unsub();
                };
            } catch (error) {
                toast.error(error.message);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [queryUserId]);

    const formatTimestamp = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            return new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            }).format(new Date(timestamp.seconds * 1000));
        }
        return 'Invalid Timestamp';
    };

    return (
        <>
            <div className="home">
                <Sidebar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <ToastContainer
                            position="top-right"
                            autoClose={2400}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light" />
                        {isLoading && <Loader />}
                        <div className="bg-white card mb-4 order-list shadow-sm" style={{ borderRadius: '20px' }} data-aos="zoom-in" data-aos-delay='100'>
                            <div className="p-4">
                                <div className="media">
                                    <div className="media-body">
                                        <i className="d-flex justify-content-end text-dark">Query TimeStamp {formatTimestamp(data.timestamp)}</i>
                                        <i className="d-flex justify-content-end text-dark">
                                            {data.updatedAt && `${data.status} on ${formatTimestamp(data.updatedAt)}`}
                                        </i>
                                        <h4 className="mb-2">
                                            <i className="icofont-location-arrow me-2 text-secondary">{data.businessName}</i>
                                        </h4>
                                        <div className="d-flex flex-column align-items-start mb-3">
                                            <i className="icofont-list me-2 text-secondary">{data.name}</i>
                                        </div>
                                        <div className="d-flex flex-column align-items-start mb-3">
                                            <i className="icofont-list me-2 text-success">Query ID #{queryUserId}</i>
                                            <div className="d-flex justify-content-between mb-1">
                                                <i className="icofont-list me-2 text-success">User ID #{data.userID}</i>
                                            </div>
                                            <div className="d-flex align-items-center mb-1">
                                                <i className="icofont-location-arrow me-2 text-secondary">+91 {data.phoneNumber}</i>
                                            </div>
                                            <div className="d-flex align-items-center mb-1">
                                                <i className="icofont-location-arrow me-2 text-secondary">+91 {data.email}</i>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start mb-3">
                                            <div className="d-flex justify-content-between mb-1">
                                                <i className="icofont-list me-2 text-success">Subject {data.subject}</i>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-start mb-3">
                                            <div className="d-flex justify-content-between mb-1">
                                                <i className="icofont-list me-2 text-success">Message {data.message}</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
