import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { DataGrid } from "@mui/x-data-grid";
import 'react-toastify/dist/ReactToastify.css';
import { getDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { auth, db } from "../../Config/Config";
import './style.css';
import { Link } from "react-router-dom";
import Navbar from './Navbar';
import Loader from '../Loader/Loader';


export default function ManageUsers() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const uid = currentUser.uid;
                    const userRef = doc(db, 'businessUsers', uid);
                    const docSnapshot = await getDoc(userRef);
                    const userData = docSnapshot.data();
                    const manageUsers = userData.manageUsers || [];

                    const linkedUsers = manageUsers.map(user => ({
                        id: user.uid,
                        email: user.email
                    }));

                    setData(linkedUsers);
                    setIsLoading(false);
                }
            } catch (error) {
                toast.error(error.message);
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleDelete = async (id) => {
        setIsLoading(true);
        try {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const uid = currentUser.uid;
                const userRef = doc(db, 'businessUsers', uid);
                const docSnapshot = await getDoc(userRef);
                const userData = docSnapshot.data();

                const manageUsers = userData.manageUsers || [];
                const updatedUsers = manageUsers.filter(user => user.uid !== id);

                await updateDoc(userRef, { manageUsers: updatedUsers });

                const userDocRef = doc(db, 'businessAdminUsers', id);
                deleteDoc(userDocRef);
                toast.success('Successfully Deleted the User!!');

                setData(prevState => prevState.filter(user => user.id !== id));
            }
        } catch (err) {
            toast.error(err.message);
        }
        setIsLoading(false);
    };

    const columns = [
        {
            field: 'id',
            headerName: 'User ID',
            width: 300,
        },
        {
            field: "email",
            headerName: "E-mail ID",
            width: 300,
        },
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div
                            className="deleteButton effect effect-2 mb-1"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        }
    ];

    return (
        <>
            <div className="home">
                <Navbar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <div className="datatable">
                            <ToastContainer
                                position="top-right"
                                autoClose={2400}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" />
                            {isLoading && <Loader />}
                            <div className="datatableTitle">
                                Manage Users
                                <Link to="/business/addNewUser" className="link">
                                    + Add New User
                                </Link>
                            </div>
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    getRowHeight={({ id, densityFactor }) => {
                                        return 60 * densityFactor;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
