import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../../Config/Config";
import { serverTimestamp, addDoc, collection, updateDoc, doc, arrayUnion } from "firebase/firestore";
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css";
import Navbar from './Navbar';

export default function ContactAdmin() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const scrollRef = useRef();
    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();
        const formFields = form.current.elements;
        const isFormValid = Array.from(formFields).every((field) => field.value.trim());
        if (!isFormValid) {
            toast.error('Please fill all the required fields.');
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        setIsLoading(true);

        const currentUser = auth.currentUser;

        if (!currentUser) {
            toast.error('Please log in to send a message.');
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
                navigate('/login');
            }, 2000);
            return;
        }

        const queryData = {
            userID: currentUser.uid,
            name: formFields.name.value,
            email: formFields.email.value,
            businessName: formFields.company.value,
            phoneNumber: formFields.phone.value,
            subject: formFields.subject.value,
            message: formFields.message.value,
            timestamp: serverTimestamp(),
        };

        try {
            const queryCollectionRef = collection(db, 'QueryBusinessUsers');
            const queryDocRef = await addDoc(queryCollectionRef, queryData);

            const userDocRef = doc(db, 'businessUsers', currentUser.uid);
            await updateDoc(userDocRef, {
                queriesID: arrayUnion(queryDocRef.id)
            });

            setIsLoading(false);
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            toast.success('Query Send Successfully. Our Team will contact you soon!!');
            form.current.reset();
        } catch (error) {
            setIsLoading(false);
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            toast.error('Error Sending Query. Please try again later.');
        }
    };

    return (
        <>
            <div ref={scrollRef}></div>
            <ToastContainer
                position="top-right"
                autoClose={2400}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            {isLoading && <Loader />}
            <div className="home">
                <Navbar />
                <div className="homeContainer">
                    <section className="get-in-touch">
                        <h1 className="title">Contact Us</h1>
                        <form className="contact-form row" ref={form} onSubmit={sendEmail}>
                            <div className="form-field col-lg-6">
                                <input id="name" className="input-text js-input" type="text" name="name" placeholder='Name' required />
                            </div>
                            <div className="form-field col-lg-6">
                                <input id="company" className="input-text js-input" type="text" name="company" placeholder='Business Name' required />
                            </div>
                            <div className="form-field col-lg-6">
                                <input id="email" className="input-text js-input" type="email" name="email" placeholder='Registered Email Address' required />
                            </div>
                            <div className="form-field col-lg-6">
                                <input id="phone" className="input-text js-input" type="text" name="phone" placeholder='Contact Number' required />
                            </div>
                            <div className="form-field col-lg-12">
                                <input id="subject" className="input-text js-input" type="text" name="subject" placeholder='Subject' required />
                            </div>
                            <div className="form-field col-lg-12">
                                <input id="message" className="input-text js-input" type="text" name="message" placeholder='Message' required />
                            </div>
                            <div className="form-field col-lg-12">
                                <div className="text-center">
                                    <input className="submit-btn" type="submit" value="Submit" />
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </>
    )
}
