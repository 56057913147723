import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, serverTimestamp, query, where, deleteDoc } from 'firebase/firestore';
import { auth, db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from "@mui/x-data-grid";
import '../Shops/style.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function VerifyBusinessUsers() {
    const [businessUsers, setBusinessUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleStatusChange = async (userId, status) => {
        try {
            setSelectedStatus(status);
            setSelectedUserId(userId);
            setIsDialogOpen(true);
        } catch (error) {
            toast.error("Error updating User Status. Please Contact the Administrator or Try after Sometime!!");
        }
    };

    const updateUserStatus = async (userId, status) => {
        setIsLoading(true);
        try {
            const userRef = doc(db, 'businessUsers', userId);
            await updateDoc(userRef, {
                UserStatus: status,
                updatedAt: serverTimestamp()
            });

            if (status === 'Rejected') {
                await deleteDoc(userRef)
                    .then(() => {
                        toast.success("Document deleted successfully from Firestore.");
                    })
                    .catch((error) => {
                        toast.error("Error deleting document from Firestore. Please check the console for details.");
                    });
            }
        } catch (error) {
            toast.error("Error updating User Status. Please Contact the Administrator or Try after Sometime!!");
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        const unsubscribeAuthStateChange = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const q = query(collection(db, 'businessUsers'), where('UserStatus', 'in', ['', 'In Progress', 'Rejected']));
                    const querySnapshot = await getDocs(q);

                    const usersData = [];
                    querySnapshot.forEach((doc) => {
                        const userFirstName = doc.data()?.FirstName || '';
                        const userLastName = doc.data()?.LastName || '';
                        const userFullName = userFirstName + ' ' + userLastName;

                        usersData.push({
                            id: doc.id,
                            ...doc.data(),
                            userFullName: userFullName,
                        });
                    });
                    setBusinessUsers(usersData);
                } catch (error) {
                    toast.error('Error Fetching Business Users. Please Try After SomeTime!!');
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        });

        return () => {
            unsubscribeAuthStateChange();
        };
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'Business User ID',
            width: 270,
            renderCell: (params) => {
                const businessUserId = params.value;
                return (
                    <div>{businessUserId}</div>
                );
            },
        },

        {
            field: 'BusinessGSTIN',
            headerName: 'GSTIN',
            width: 100,
            renderCell: (params) => {
                const businessGSTIN = params.value;
                return <div>{businessGSTIN}</div>;
            }
        },

        {
            field: 'BusinessName',
            headerName: 'Business Name',
            width: 170,
            renderCell: (params) => {
                const businessName = params.value;
                return <div>{businessName}</div>;
            }
        },

        {
            field: 'ServiceAvailable',
            headerName: 'Service Available',
            width: 150,
            renderCell: (params) => {
                const services = params.value || [];
                return (
                    <div>
                        {services.map((service) => (
                            <div key={service}>{service}</div>
                        ))}
                    </div>
                );
            }
        },

        {
            field: 'BusinessEmail',
            headerName: 'Business Email',
            width: 200,
            renderCell: (params) => {
                const businessEmail = params.value;
                return <div>{businessEmail}</div>;
            }
        },

        { field: 'userFullName', headerName: 'Owner Name', width: 150 },

        {
            field: 'UserStatus',
            headerName: 'User Status',
            width: 180,
            renderCell: (params) => {
                const userStatus = params.value || { seconds: 0 };;
                return (
                    <FormControl variant="outlined" size="small" margin='normal' fullWidth>
                        <InputLabel>Select Status</InputLabel>
                        <Select
                            value={userStatus}
                            onChange={(event) => {
                                handleStatusChange(params.row.id, event.target.value);
                            }}
                            label="Select Status"
                        >
                            <MenuItem value={null} disabled>Select Status</MenuItem>
                            <MenuItem value="Verified">Verified</MenuItem>
                            <MenuItem value="In Progress">In Progress</MenuItem>
                            <MenuItem value="Rejected">Rejected</MenuItem>
                        </Select>
                    </FormControl>
                );
            }
        },
    ];
    return (
        <>
            <div className="datatable">
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                {isLoading && <Loader />}
                <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm Status Change</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to change the status to {selectedStatus}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                if (selectedStatus) {
                                    updateUserStatus(selectedUserId, selectedStatus);
                                    setIsDialogOpen(false);
                                    setSelectedStatus('');
                                    setSelectedUserId(null);
                                }
                            }}
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <div style={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        rows={businessUsers}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        checkboxSelection
                        disableSelectionOnClick
                        getRowHeight={() => 'auto'}
                        autosizeOptions={{
                            columns: ['id', 'products', 'userAddress', 'userPhone'],
                            includeOutliers: true,
                            includeHeaders: false,
                        }}
                    />
                </div>
            </div>
        </>
    )
}
