import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDoc, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../Config/Config';
import Navbar from './Navbar';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

export default function ModifyProduct() {
    const { id } = useParams();
    const [data, setData] = useState({
        Name: '',
        DietaryPreference: '',
        Category: '',
        Price: '',
        Customization: [],
        subtitle: '',
        packingFee: 0,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [availableCategories, setAvailableCategories] = useState([]);
    const navigate = useNavigate();

    const handleAddCustomization = () => {
        const newCustomization = {
            customizationName: '',
            customizationPrice: 0,
        };

        setData({
            ...data,
            Customization: [...data.Customization, newCustomization],
        });
    };

    const handleCustomizationType = (index, event) => {
        const selectedType = event.target.value;

        const updatedCustomizations = [...data.Customization];
        updatedCustomizations[index] = {
            ...updatedCustomizations[index],
            customizationType: selectedType,
        };

        setData({
            ...data,
            Customization: updatedCustomizations,
        });
    };

    const handleRemoveCustomization = (index) => {
        const customizations = [...data.Customization];
        customizations.splice(index, 1);

        setData({
            ...data,
            Customization: customizations,
        });
    };

    const handleCustomizationInput = (index, e) => {
        const { name, value } = e.target;
        const customizations = [...data.Customization];

        const updatedValue = name === 'customizationPrice' ? parseFloat(value) : value;

        customizations[index] = {
            ...customizations[index],
            [name]: updatedValue,
        };

        setData({
            ...data,
            Customization: customizations,
        });
    };

    useEffect(() => {
        const fetchData = async (uid) => {
            setIsLoading(true);
            try {
                const userRef = doc(db, 'businessUsers', uid);
                const unsub = onSnapshot(
                    userRef,
                    (docSnapshot) => {
                        if (docSnapshot.exists()) {
                            const userData = docSnapshot.data();
                            const products = userData.products || [];
                            const userCategories = userData.productCategory || [];
                            setAvailableCategories(userCategories);
                            const updateProduct = products.find((product) => product.Id === id);
                            if (updateProduct) {
                                setData(updateProduct);
                            }
                        }
                    },
                );
                return () => {
                    unsub();
                };
            } catch (error) {
                toast.error(error.message);
            }
            setIsLoading(false);
        };

        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                fetchData(user.uid);
            } else {
                setData([]);
            }
            setIsLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, [id]);

    const handleUpdate = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const currentUser = auth.currentUser;

        if (currentUser) {
            const uid = currentUser.uid;
            const userRef = doc(db, 'businessUsers', uid);

            try {
                const docSnapshot = await getDoc(userRef);
                const userData = docSnapshot.data();

                const products = userData.products || [];

                const updatedProducts = products.map((product) => {
                    if (product.Id === id) {
                        return {
                            ...data,
                        };
                    }
                    return product;
                });

                await updateDoc(userRef, { products: updatedProducts });

                setIsLoading(false);
                toast.success('Successfully Updated the Product!');
                setTimeout(() => {
                    navigate('/business/product');
                }, 2500);
            } catch (error) {
                setIsLoading(false);
                toast.error(error.message);
            }
        }
    };

    const handleInput = (e) => {
        const id = e.target.id;
        const value = e.target.id === 'packingFee' ? parseFloat(e.target.value) : e.target.value;
        setData({ ...data, [id]: value });
    };

    const handleCategoryInput = (event) => {
        const selectedServices = event.target.value;
        setData({ ...data, Category: selectedServices });
    };

    const handleDietPrefInput = (event) => {
        const selectedServices = event.target.value;
        setData({ ...data, DietaryPreference: selectedServices });
    };


    return (
        <>
            <div className="home">
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <Navbar />
                <div className="homeContainer">
                    <div className="container-fluid px-1 py-5 mx-auto">
                        <div className="row d-flex justify-content-center">
                            {isLoading && <Loader />}
                            <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center" style={{ paddingTop: '70px' }}>
                                <div className="prdcard">
                                    <h1>Modify Products</h1>
                                    <form className="form-card" onSubmit={handleUpdate}>
                                        <div className="row justify-content-between text-left">
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="Name" className="form-control-label px-3">
                                                    Product name<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <TextField
                                                    type="text"
                                                    id="Name"
                                                    name="Name"
                                                    placeholder="Product name"
                                                    value={data.Name}
                                                    onChange={handleInput}
                                                    variant="outlined"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="Name" className="form-control-label px-3">
                                                    Product Subtitle
                                                </InputLabel>
                                                <TextField
                                                    type="text"
                                                    id="subtitle"
                                                    name="subtitle"
                                                    placeholder="Product Subtitle"
                                                    value={data.subtitle}
                                                    onChange={handleInput}
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-between text-left">
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="Category" className="form-control-label px-3">
                                                    Category<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Select
                                                        id="Category"
                                                        name="Category"
                                                        value={data.Category}
                                                        onChange={handleCategoryInput}
                                                        required
                                                    >
                                                        <MenuItem value="" disabled>Select a category</MenuItem>
                                                        {availableCategories.map(category => (
                                                            <MenuItem key={category} value={category}>{category}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="DietaryPreference" className="form-control-label px-3">
                                                    Dietary Preference<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Select
                                                        id="DietaryPreference"
                                                        name="DietaryPreference"
                                                        value={data.DietaryPreference}
                                                        onChange={handleDietPrefInput}
                                                        required
                                                    >
                                                        <MenuItem value="" disabled>Select Dietary Preference</MenuItem>
                                                        <MenuItem value="Veg">Vegetarian</MenuItem>
                                                        <MenuItem value="Non Veg">Non Vegetarian</MenuItem>
                                                        <MenuItem value="Egg">Eggetarian</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between text-left">
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="Price" className="form-control-label px-3">
                                                    Price<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <TextField
                                                    type="number"
                                                    id="Price"
                                                    name="Price"
                                                    placeholder="Price"
                                                    value={data.Price}
                                                    onChange={handleInput}
                                                    variant="outlined"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="Price" className="form-control-label px-3">
                                                    Packing Charge
                                                </InputLabel>
                                                <TextField
                                                    type="number"
                                                    id="packingFee"
                                                    name="packingFee"
                                                    placeholder="Packing Charge"
                                                    value={data.packingFee}
                                                    onChange={handleInput}
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                        <div className="row text-left">
                                            {data.Customization && data.Customization.length > 0 && (
                                                data.Customization.map((customization, index) => (
                                                    <div key={index}>
                                                        <div className="row">
                                                            <div className="form-group col-sm-4 flex-column d-flex mt-3">
                                                                <InputLabel htmlFor={`customizationName${index}`} className="form-control-label px-3">
                                                                    Customization Name<span className="text-danger"> *</span>
                                                                </InputLabel>
                                                                <TextField
                                                                    type="text"
                                                                    id={`customizationName${index}`}
                                                                    name="customizationName"
                                                                    placeholder="Customization Name"
                                                                    onChange={(e) => handleCustomizationInput(index, e)}
                                                                    value={customization.customizationName}
                                                                    variant="outlined"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-sm-4 flex-column d-flex mt-3">
                                                                <InputLabel htmlFor={`customizationPrice${index}`} className="form-control-label px-3">
                                                                    Customization Price<span className="text-danger"> *</span>
                                                                </InputLabel>
                                                                <TextField
                                                                    type="number"
                                                                    id={`customizationPrice${index}`}
                                                                    name="customizationPrice"
                                                                    placeholder="Customization Price"
                                                                    onChange={(e) => handleCustomizationInput(index, e)}
                                                                    value={customization.customizationPrice}
                                                                    variant="outlined"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group col-sm-4 flex-column d-flex mt-3">
                                                                <InputLabel htmlFor={`customizationType${index}`} className="form-control-label px-3">
                                                                    Customization Type<span className="text-danger"> *</span>
                                                                </InputLabel>
                                                                <FormControl fullWidth variant="outlined">
                                                                    <InputLabel id={`CustomizationTypeLabel${index}`}>Customization Type<span className="text-danger"> *</span></InputLabel>
                                                                    <Select
                                                                        labelId={`CustomizationTypeLabel${index}`}
                                                                        id={`customizationType${index}`}
                                                                        onChange={(e) => handleCustomizationType(index, e)}
                                                                        value={customization.customizationType}
                                                                        label="Customization Type"
                                                                        required
                                                                    >
                                                                        <MenuItem value="" disabled>Select Customization Type</MenuItem>
                                                                        <MenuItem value={'Size'}>Size</MenuItem>
                                                                        <MenuItem value={'Crust'}>Crust</MenuItem>
                                                                        <MenuItem value={'Toppings'}>Toppings</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className="form-group col-sm-4 d-flex align-items-end mt-1">
                                                                <button
                                                                    type="button"
                                                                    className="btn-block btn-danger"
                                                                    onClick={() => handleRemoveCustomization(index)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                            {/* <div className="form-group col-sm-12">
                                                <button type="button" className="btn-block btn-info" onClick={handleAddCustomization}>
                                                    Add Customization
                                                </button>
                                            </div> */}
                                        </div>
                                        <div className="row justify-content-center mt-5">
                                            <div className="form-group col-sm-6">
                                                <button type="submit" className="btn-block btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
