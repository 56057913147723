import React, { useEffect, useState } from 'react';
import '../Shops/style.css';
import { DataGrid } from "@mui/x-data-grid";
import { query, collection, where, doc, getDoc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar/Sidebar';

export default function OrderDetails() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getUserDetails = async (userId) => {
        const userRef = doc(db, 'users', userId);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
            return userSnapshot.data();
        }
        return null;
    };

    const getShopUserDetails = async (shopUserId) => {
        const businessUserRef = doc(db, 'businessUsers', shopUserId);
        const businessUserSnapshot = await getDoc(businessUserRef);
        if (businessUserSnapshot.exists()) {
            return businessUserSnapshot.data();
        }
        return null;
    };

    useEffect(() => {
        const unsubscribeAuthStateChange = auth.onAuthStateChanged((user) => {
            if (user) {
                const ordersRef = collection(db, 'Orders');
                const ordersQuery = query(ordersRef, where('status', 'in', ['Delivered', 'Cancelled']));

                let initialFetch = true;

                const unsubscribe = onSnapshot(
                    ordersQuery,
                    async (querySnapshot) => {
                        if (initialFetch) {
                            setIsLoading(true);
                            initialFetch = false;
                        }

                        try {
                            const ordersData = await Promise.all(
                                querySnapshot.docs.map(async (doc) => {
                                    const order = doc.data();
                                    const userDetails = await getUserDetails(order.userId);
                                    const businessUserDetails = await getShopUserDetails(order.shopId);

                                    const userFirstName = userDetails?.FirstName || '';
                                    const userLastName = userDetails?.LastName || '';

                                    const formattedFirstName = userFirstName.charAt(0).toUpperCase() + userFirstName.slice(1).toLowerCase();
                                    const formattedLastName = userLastName.charAt(0).toUpperCase() + userLastName.slice(1).toLowerCase();

                                    const userFullName = formattedFirstName + ' ' + formattedLastName;

                                    return {
                                        id: doc.id,
                                        ...order,
                                        userFullName: userFullName,
                                        shopName: businessUserDetails?.BusinessName,
                                    };
                                })
                            );

                            ordersData.sort((a, b) => b.updatedAt - a.updatedAt);
                            setData(ordersData);
                        } catch (error) {
                            toast.error('Error in Fetching the Orders History. Please Contact the Administrator!!');
                        } finally {
                            if (!initialFetch) {
                                setIsLoading(false);
                            }
                        }
                    },
                    (error) => {
                        toast.error(error.message);
                        setIsLoading(false);
                    }
                );

                return () => {
                    unsubscribe();
                };
            } else {
                setIsLoading(false);
                setData([]);
            }
        });

        return () => {
            unsubscribeAuthStateChange();
        };
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'Order ID',
            width: 210,
            renderCell: (params) => {
                const orderId = params.value;
                return (
                    <div
                        style={{ cursor: 'pointer', color: '#1976D2' }}
                        data-bs-toggle="modal"
                        data-bs-target={`#bootstrapModal${orderId}`}
                    >
                        {orderId}
                    </div>
                );
            },
        },

        { field: 'shopName', headerName: 'Shop Name', width: 120 },

        {
            field: 'timestamp',
            headerName: 'Order Placed Time',
            width: 180,
            renderCell: (params) => {
                const timestamp = params.value || { seconds: 0 };
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp.seconds * 1000));
                return <div>{formattedTimestamp}</div>;
            }
        },

        {
            field: 'totalAmount', headerName: 'Amount', width: 70, renderCell: (params) => {
                const amount = params.value;
                return <div>&#8377;{amount}</div>;
            }
        },

        { field: 'userFullName', headerName: 'User Name', width: 120 },

        {
            field: 'userPhone', headerName: 'User Phone', width: 140, renderCell: (params) => {
                const phoneNumber = params.value;
                const formattedPhoneNumber = `+91 ${phoneNumber}`;
                return <div>{formattedPhoneNumber}</div>;
            }
        },

        { field: 'OrderType', headerName: 'Order Type', width: 90 },

        {
            field: 'status',
            headerName: 'Order Status',
            width: 100,
            renderCell: (params) => {
                const orderStatus = params.value;
                return <div>{orderStatus}</div>;
            }
        },
        {
            field: 'updatedAt',
            headerName: 'Delivered Time',
            width: 200,
            renderCell: (params) => {
                const timestamp = params.value || { seconds: 0 };
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp.seconds * 1000));
                return <div>{formattedTimestamp}</div>;
            }
        },
    ];
    return (
        <>
            {data.map((order) => (
                <div className="modal fade" id={`bootstrapModal${order.id}`} tabIndex="-1" aria-labelledby={`bootstrapModalLabel${order.id}`} aria-hidden="true" key={`bootstrap${order.id}`}>
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ borderRadius: '15px', borderTop: '3px solid #f37a27', borderBottom: '3px solid #f37a27' }}>
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start text-black p-2">
                                <h5 className="modal-title text-uppercase text-center mb-1" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>{order.shopName}</h5>
                                <h6 className="modal-title text-center mb-0" id="bootstrapModal" style={{ fontWeight: 'lighter' }}>GSTIN: {order.BusinessGSTIN}</h6>
                                <h6 className="modal-title text-center mb-0" id="bootstrapModal" style={{ fontWeight: 'lighter' }}>Phone No: +91 {order.shopPhone}</h6>
                                <hr />
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order ID</p>
                                        <p>{order.id}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Payment ID</p>
                                        <p>{order.paymentId}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">User ID</p>
                                        <p>{order.userId}</p>
                                    </div>
                                    {/* <div className="col mb-1">
                                        <p className="small text-muted mb-1">Payment ID</p>
                                        <p>{order.paymentId}</p>
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">User Name</p>
                                        <p>{order.userFullName}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Phone Number</p>
                                        <p>+91 {order.userPhone}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    {order.OrderType === 'Delivery' ? (<div className="col mb-1">
                                        <p className="small text-muted mb-1">Delivery Address</p>
                                        <p>{order.userAddress}</p>
                                    </div>) : (<div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Type</p>
                                        <p>{order.OrderType}</p>
                                    </div>)}
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Status</p>
                                        <p>{order.status}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Placed Time</p>
                                        <p>{new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        }).format(new Date(order.timestamp.seconds * 1000))}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Delivered Time</p>
                                        <p>{new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        }).format(new Date(order.updatedAt.seconds * 1000))}</p>
                                    </div>
                                </div>
                                {order.orderInstruction ? (<div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Instruction</p>
                                        <p>{order.orderInstruction}</p>
                                    </div>
                                </div>) : null}
                                <h4 className="mt-2 mb-2" style={{ color: "#35558a" }}>Payment Summary</h4>
                                <hr className="mt-2 mb-4" style={{ height: "0", backgroundColor: "transparent", opacity: ".75", bordertop: "2px dashed #9e9e9e" }} />
                                <div>
                                    {order.products && order.products.map(product => (
                                        <div className="d-flex justify-content-between" key={product.productId}>
                                            <p className="mb-0">{product.name} (Qty: {product.quantity})</p>
                                            <p className="mb-0">&#8377;{product.price * product.quantity}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className="d-flex justify-content-between mt-2">
                                    <h6 className="fw-bold">Sub Total</h6>
                                    <p className="lead fw-bold mb-0" style={{ fontWeight: 'bold', fontSize: '18px' }}>&#8377;{order.itemSubtotal.toFixed(2)}</p>
                                </div>
                                <hr className="mt-1" />


                                <div className="d-flex justify-content-between">
                                    <p className="mb-0">Delivery Charges</p>
                                    <p className="mb-0">&#8377;{order.deliveryCharge}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="mb-0">Convenience Fee</p>
                                    <p className="mb-0">&#8377;{order.convenienceCharge}</p>
                                </div>

                                {order.isGST ? (
                                    <>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">CGST (2.5%)</p>
                                            <p className="mb-0">&#8377;{(0.025 * order.itemSubtotal).toFixed(2)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">SGST (2.5%)</p>
                                            <p className="mb-0">&#8377;{(0.025 * order.itemSubtotal).toFixed(2)}</p>
                                        </div>
                                    </>) : null}

                                <div className="d-flex justify-content-between mt-2">
                                    <h5 className="fw-bold">Total Paid</h5>
                                    <p className="lead fw-bold mb-0" style={{ color: "#f37a27" }}>&#8377;{order.totalAmount}</p>
                                </div>

                                <hr className="mt-1" />
                                <h6 className="modal-title text-center mb-1" id="bootstrapModal">FSSAI License Number: {order.FSSAI}</h6>
                                <h5 className="modal-title text-uppercase text-center mb-0" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>Thank You!!</h5>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="home">
                <Sidebar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <div className="datatable">
                            <ToastContainer
                                position="top-right"
                                autoClose={2400}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" />
                            {isLoading && <Loader />}
                            <div className="datatableTitle">
                                Orders Details
                            </div>
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 10, page: 0 },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50]}
                                    disableSelectionOnClick
                                    getRowHeight={({ id, densityFactor }) => {
                                        return 60 * densityFactor;
                                    }}
                                    autosizeOptions={{
                                        columns: ['id', 'products', 'userAddress', 'userPhone'],
                                        includeOutliers: true,
                                        includeHeaders: false,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
