import React, { useEffect, useState, useCallback } from 'react'
import './style.css';
import { DataGrid } from "@mui/x-data-grid";
import { query, collection, where, doc, updateDoc, getDoc, serverTimestamp, onSnapshot, arrayUnion, arrayRemove } from "firebase/firestore";
import { auth, db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ActiveOrder() {
    const [data, setData] = useState([]);
    const [shop, setShop] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState('');
    // const [deliveryTime, setDeliveryTime] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [config, setConfig] = useState([]);


    const handleStatusChange = async (orderId, status) => {
        try {
            if (status === 'Delivered' || status === 'Cancelled') {
                setSelectedStatus(status);
                setSelectedOrderId(orderId);
                setIsDialogOpen(true);
            } else {
                const orderData = data.find(item => item.id === orderId);
                if (orderData) {
                    await updatePaymentStatus({
                        orderId: orderId,
                        orderStatus: status,
                        paymentId: orderData.paymentId,
                        paisa: Math.round(parseFloat(orderData.totalAmount) * 100),
                        transferPaisa: Math.round((orderData.itemSubtotal + (orderData.OrderType === 'Delivery' ? parseFloat(orderData.shopDeliveryCut) : 0) + (orderData.isGST ? 0.05 * orderData.itemSubtotal : 0) + (orderData.OrderType !== 'Dine-In' ? parseFloat(orderData.packingFee) : 0)) * 100),
                        accountId: shop.routeId,
                        isTest: orderData.isTest,
                        isPremium: shop.isPremium
                    });
                } else {
                    toast.error("Error updating Order Status. Order data not found for orderId:", orderId);
                }
            }
        } catch (error) {
            toast.error("Error updating Order Status. Please Contact the Administrator or Try after Sometime!!");
        }
    };

    const handlePremiumStatusChange = async (orderId, status) => {
        try {
            if (status === 'Delivered' || status === 'Cancelled' || status === 'In Progress') {
                setSelectedStatus(status);
                setSelectedOrderId(orderId);
                setIsDialogOpen(true);
            } else {
                const orderData = data.find(item => item.id === orderId);
                if (orderData) {
                    await updatePaymentStatus({
                        orderId: orderId,
                        orderStatus: status,
                        paymentId: orderData.paymentId,
                        paisa: Math.round(parseFloat(orderData.totalAmount) * 100),
                        transferPaisa: Math.round((orderData.itemSubtotal + (orderData.OrderType === 'Delivery' ? parseFloat(orderData.shopDeliveryCut) : 0) + (orderData.isGST ? 0.05 * orderData.itemSubtotal : 0) + (orderData.OrderType !== 'Dine-In' ? parseFloat(orderData.packingFee) : 0)) * 100),
                        accountId: shop.routeId,
                        isTest: orderData.isTest,
                        isPremium: shop.isPremium
                    });
                } else {
                    toast.error("Error updating Order Status. Order data not found for orderId:", orderId);
                }
            }
        } catch (error) {
            toast.error("Error updating Order Status. Please Contact the Administrator or Try after Sometime!!");
        }
    };

    // const handleTimeChange = async (orderId, DeliveryTime) => {
    //     try {
    //         await updateOrderDeliveryTime(orderId, DeliveryTime);
    //         if (/^\d+$/.test(DeliveryTime) || DeliveryTime === '') {
    //             setDeliveryTime(DeliveryTime);
    //         }
    //     } catch (error) {
    //         toast.error("Error updating Delivery Time. Please Contact the Administrator or Try after Sometime!!")
    //     }
    // };

    const capturePayment = async (paymentId, paisa, url) => {
        try {
            url = `${url}payment_id=${paymentId}&amt=${paisa}`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({})
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 'captured') {
                    return true;
                }
            }
        } catch (e) {
            console.error(e);
            toast.error("Error capturing payment. Please Check your Internet Connection.");
            return false;
        }
    };

    const routePayment = async (paymentId, transferPaisa, accountId, url) => {
        try {
            url = `${url}account_id=${accountId}&payment_id=${paymentId}&amt=${transferPaisa}`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({})
            });

            if (response.ok) {
                const data = await response.json();
                return data.transferID || '';
            }
        } catch (e) {
            console.error(e);
            toast.error("Error transferring payment. Please Check your Internet Connection.");
            return '';
        }
    };

    const refundPayment = async (paymentId, paisa, url) => {
        try {
            url = `${url}amt=${paisa}&payment_id=${paymentId}`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify({})
            });

            if (response.ok) {
                const data = await response.json();
                return data.refundID || '';
            }
        } catch (e) {
            console.error(e);
            toast.error("Error refunding payment. Please Check your Internet Connection.");
            return '';
        }
    };

    const updateOrderStatusDelivered = async (orderId) => {
        try {
            const orderRef = doc(db, 'Orders', orderId);
            const orderSnapshot = await getDoc(orderRef);
            const shopId = orderSnapshot.get('shopId');
            const userId = orderSnapshot.get('userId');

            const shopRef = doc(db, 'businessUsers', shopId);
            const shopSnapshot = await getDoc(shopRef);;

            const usersList = shopSnapshot.get('users') || [];

            if (!usersList.includes(userId)) {
                const updatedUsersList = arrayUnion(userId);
                await updateDoc(shopRef, { users: updatedUsersList });
            }

            const existingPayments = shopSnapshot.get('thirtyDaysPayment') || [];
            const formattedDate = orderSnapshot.get('timestamp').toDate().toISOString().split('T')[0];

            const existingIndex = existingPayments.findIndex(
                (payment) => payment.timestamp.toDate().toISOString().split('T')[0] === formattedDate
            );

            if (existingIndex !== -1) {
                existingPayments[existingIndex].amount += orderSnapshot.get('itemSubtotal') + (orderSnapshot.get('OrderType') === 'Delivery' ? parseFloat(orderSnapshot.get('shopDeliveryCut')) : 0) + (orderSnapshot.get('OrderType') !== 'Dine-In' ? parseFloat(orderSnapshot.get('packingFee')) : 0) + (orderSnapshot.get('isGST') ? 0.05 * orderSnapshot.get('itemSubtotal') : 0);
            } else {
                existingPayments.push({
                    timestamp: orderSnapshot.get('timestamp'),
                    amount: orderSnapshot.get('itemSubtotal') + (orderSnapshot.get('OrderType') === 'Delivery' ? parseFloat(orderSnapshot.get('shopDeliveryCut')) : 0) + (orderSnapshot.get('OrderType') !== 'Dine-In' ? parseFloat(orderSnapshot.get('packingFee')) : 0) + (orderSnapshot.get('isGST') ? 0.05 * orderSnapshot.get('itemSubtotal') : 0),
                });
            }

            await updateDoc(shopRef, { thirtyDaysPayment: existingPayments });

            if (existingPayments.length > 29) {
                await updateDoc(shopRef, { thirtyDaysPayment: arrayRemove(existingPayments[0]) });
            }

            const ordersByCategory = shopSnapshot.get('ordersByCategory') || [];

            orderSnapshot.get('products').forEach((product) => {
                const category = product.category;
                const existingCategoryIndex = ordersByCategory.findIndex(
                    (category) => category.category === product.category
                );

                if (existingCategoryIndex !== -1) {
                    ordersByCategory[existingCategoryIndex].quantity += product.quantity;
                } else {
                    ordersByCategory.push({
                        category: category,
                        quantity: product.quantity,
                    });
                }
            });

            await updateDoc(shopRef, { ordersByCategory: ordersByCategory });
        } catch (error) {
            toast.error('Error updating Order Status. Please Contact the Administrator or Try after Sometime!!');
        }
    };

    const updatePaymentStatus = async ({
        orderId,
        orderStatus,
        paymentId = '',
        paisa = 0,
        transferPaisa = 0,
        accountId = '',
        isTest = false,
        isPremium = false
    }) => {
        try {
            const orderRef = doc(db, 'Orders', orderId);
            let transferID = '';

            if (orderStatus === 'Delivered') {
                updateOrderStatusDelivered(orderId);

                if (!isTest) {
                    const status = await capturePayment(paymentId, paisa, config.apiLinkCapture);
                    if (!status) return false;

                    transferID = await routePayment(paymentId, transferPaisa, accountId, config.apiLinkRoute);
                    if (!transferID) return false;
                }

                await updateDoc(orderRef, {
                    'status': orderStatus,
                    'updatedAt': serverTimestamp(),
                    'transferId': transferID
                });
            } else if (orderStatus === 'Cancelled') {
                if (!isPremium) {
                    let refundID = '';
                    if (!isTest) {
                        const status = await capturePayment(paymentId, paisa, config.apiLinkCapture);
                        if (!status) return false;

                        refundID = await refundPayment(paymentId, paisa, config.apiLinkRefund);
                        if (!refundID) return false;
                    }
                    await updateDoc(orderRef, {
                        'status': orderStatus,
                        'updatedAt': serverTimestamp(),
                        'refundId': refundID
                    });
                } else {
                    await updateDoc(orderRef, {
                        'status': orderStatus,
                        'updatedAt': serverTimestamp(),
                        'refundApproved': false
                    });
                    const uid = auth.currentUser.uid;
                    const shopRef = doc(db, 'businessUsers', uid);
                    const shopSnapshot = await getDoc(shopRef);
                    const refundList = shopSnapshot.get('refundOrders') || [];
                    const updatedRefundList = arrayUnion(orderId);
                    await updateDoc(shopRef, { refundOrders: updatedRefundList });
                }
            } else {
                await updateDoc(orderRef, {
                    'status': orderStatus,
                    'updatedAt': serverTimestamp()
                });
            }

            return true;
        } catch (e) {
            console.error(e);
            toast.error("Error updating order status. Please try again later!!");
            return false;
        }
    };


    // const updateOrderDeliveryTime = async (orderId, DeliveryTime) => {
    //     setIsLoading(true);
    //     try {
    //         const orderRef = doc(db, 'Orders', orderId);
    //         await updateDoc(orderRef, {
    //             DeliveryTime: DeliveryTime,
    //             updatedAt: serverTimestamp()
    //         });
    //     } catch (error) {
    //         toast.error("Error updating Delivery Time. Please Contact the Administrator or Try after Sometime!!")
    //     }
    //     setIsLoading(false);
    // };


    const fetchData = useCallback(async (uid) => {
        const ordersRef = collection(db, 'Orders');
        const ordersQuery = query(ordersRef, where('shopId', '==', uid), where('status', 'in', ['Out for Delivery', 'In Progress', 'Ready for Pickup', '']));
        const shopRef = doc(db, 'businessUsers', uid);
        const shopSnapshot = await getDoc(shopRef);
        const shopData = shopSnapshot.data();
        const configRef = doc(db, 'globals', 'businessApp');
        const configSnapshot = await getDoc(configRef);
        const configData = configSnapshot.data();
        setConfig(configData);
        setShop(shopData);

        let initialFetch = true;

        const unsubscribeOrders = onSnapshot(ordersQuery, async (querySnapshot) => {
            if (initialFetch) {
                setIsLoading(true);
                initialFetch = false;
            }

            try {
                const ordersData = await Promise.all(querySnapshot.docs.map(async (doc) => {
                    const order = doc.data();
                    return {
                        id: doc.id,
                        ...order,
                    };
                }));

                ordersData.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
                setData(ordersData);
            } catch (error) {
                toast.error('Error in Fetching the Orders History. Please Contact the Administrator!!');
            } finally {
                if (!initialFetch) {
                    setIsLoading(false);
                }
            }
        }, []);

        return () => {
            unsubscribeOrders();
        };
    }, []);

    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged((user) => {
            if (user) {
                fetchData(user.uid);
            } else {
                setData([]);
                setIsLoading(false);
            }
        });

        return () => {
            unsubscribeAuth();
        };
    }, [fetchData]);


    const columns = [
        {
            field: 'id',
            headerName: 'Order ID',
            width: 180,
            renderCell: (params) => {
                const orderId = params.value;
                return (
                    <div
                        style={{ cursor: 'pointer', color: '#1976D2' }}
                        data-bs-toggle="modal"
                        data-bs-target={`#bootstrapModal${orderId}`}
                    >
                        {orderId}
                    </div>
                );
            },
        },

        {
            field: 'timestamp',
            headerName: 'Order Placed Time',
            width: 180,
            renderCell: (params) => {
                const timestamp = params.value;
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(new Date(timestamp.seconds * 1000));
                return <div>{formattedTimestamp}</div>;
            }
        },

        {
            field: 'products',
            headerName: 'Products',
            width: 200,
            renderCell: (params) => {
                const products = params.value.map((product) => (
                    <div className="product-details" key={product.productId}>
                        <p className="product-name">{product.name} x {product.quantity}</p>
                        <p className="product-subtitle">{product.subtitle}</p>
                    </div>
                ));

                return <div>{products}</div>;
            }
        },

        {
            field: 'OrderType', headerName: 'Order Type', width: 100, renderCell: (params) => {
                const orderType = params.value;
                return <div>{orderType}</div>;
            }
        },

        {
            field: 'userName', headerName: 'User Name', width: 120, renderCell: (params) => {
                const userName = params.value;
                return <div>{userName}</div>;
            }
        },

        {
            field: 'totalAmount',
            headerName: 'Amount',
            width: 70,
            renderCell: (params) => {
                const amount = params.value;
                const shopDeliveryCut = params.row.shopDeliveryCut;
                const packingFee = params.row.packingFee;
                const isGST = params.row.isGST;
                const itemSubtotal = params.row.itemSubtotal;
                let totalAmount = itemSubtotal;
                if (shopDeliveryCut && parseFloat(shopDeliveryCut) > 0) {
                    totalAmount += parseFloat(shopDeliveryCut);
                }
                if (packingFee && parseFloat(packingFee) > 0) {
                    totalAmount += parseFloat(packingFee);
                }
                if (isGST) {
                    totalAmount += 0.05 * itemSubtotal;
                }
                return <div>&#8377;{totalAmount}</div>;
            }
        },

        {
            field: 'status',
            headerName: 'Order Status',
            width: 180,
            renderCell: (params) => {
                const orderStatus = params.value || { seconds: 0 };
                const orderType = params.row.OrderType;

                if (!shop.isPremium) {
                    let menuItems;

                    if (orderType === 'Takeaway' || orderType === 'Dine-In') {
                        menuItems = [
                            <MenuItem key="In Progress" value="In Progress">In Progress</MenuItem>,
                            <MenuItem key="Ready for Pickup" value="Ready for Pickup">Ready for Pickup</MenuItem>,
                            <MenuItem key="Delivered" value="Delivered">Delivered</MenuItem>,
                            <MenuItem key="Cancelled" value="Cancelled">Cancel</MenuItem>,
                        ];
                    } else {
                        menuItems = [
                            <MenuItem key="In Progress" value="In Progress">In Progress</MenuItem>,
                            <MenuItem key="Out for Delivery" value="Out for Delivery">Out for Delivery</MenuItem>,
                            <MenuItem key="Delivered" value="Delivered">Delivered</MenuItem>,
                            <MenuItem key="Cancelled" value="Cancelled">Cancel</MenuItem>,
                        ];
                    }

                    return (
                        <FormControl variant="outlined" size="small" margin='normal' fullWidth>
                            <InputLabel>Select Status</InputLabel>
                            <Select
                                value={orderStatus}
                                onChange={(event) => {
                                    handleStatusChange(params.row.id, event.target.value);
                                }}
                                label="Select Status"
                            >
                                {menuItems}
                            </Select>
                        </FormControl>
                    );
                } else {
                    let menuItems;
                    const allowedStatuses = ['In Progress', 'Ready for Pickup', 'Out for Delivery', 'Delivered', 'Cancelled'];
                    if (!allowedStatuses.includes(orderStatus)) {
                        menuItems = [
                            <MenuItem key="In Progress" value="In Progress">Accept</MenuItem>,
                            <MenuItem key="Cancelled" value="Cancelled">Cancel</MenuItem>
                        ];
                    } else {
                        if (orderType === 'Takeaway' || orderType === 'Dine-In') {
                            menuItems = [
                                <MenuItem key="In Progress" value="In Progress">In Progress</MenuItem>,
                                <MenuItem key="Ready for Pickup" value="Ready for Pickup">Ready for Pickup</MenuItem>,
                                <MenuItem key="Delivered" value="Delivered">Delivered</MenuItem>,
                            ];
                        } else {
                            menuItems = [
                                <MenuItem key="In Progress" value="In Progress">In Progress</MenuItem>,
                                <MenuItem key="Out for Delivery" value="Out for Delivery">Out for Delivery</MenuItem>,
                                <MenuItem key="Delivered" value="Delivered">Delivered</MenuItem>,
                            ];
                        }
                    }

                    return (
                        <FormControl variant="outlined" size="small" margin='normal' fullWidth>
                            <InputLabel>Select Status</InputLabel>
                            <Select
                                value={orderStatus}
                                onChange={(event) => {
                                    if (event.target.value === 'In Progress') {
                                        handlePremiumStatusChange(params.row.id, 'In Progress');
                                    } else if (event.target.value === 'Cancelled') {
                                        handlePremiumStatusChange(params.row.id, 'Cancelled');
                                    } else {
                                        handlePremiumStatusChange(params.row.id, event.target.value);
                                    }
                                }}
                                label="Select Status"
                            >
                                {menuItems}

                            </Select>
                        </FormControl>
                    );
                }
            }
        },

        // {
        //     field: 'DeliveryTime',
        //     headerName: 'Order Completion Time',
        //     width: 200,
        //     renderCell: (params) => {
        //         const orderTime = params.value || { seconds: 0 };;
        //         return (
        //             <FormControl variant="outlined" size="small" margin='normal' fullWidth>
        //                 <InputLabel>Select Delivery Time</InputLabel>
        //                 <Select
        //                     value={orderTime}
        //                     onChange={(event) => {
        //                         handleTimeChange(params.row.id, event.target.value);
        //                     }}
        //                     label="Select Delivery Time"
        //                 >
        //                     <MenuItem value={null} disabled>Select Delivery Time</MenuItem>
        //                     <MenuItem value="Less Than 15">Less Than 15 minutes</MenuItem>
        //                     <MenuItem value="15 - 30">15 - 30 minutes</MenuItem>
        //                     <MenuItem value="30 - 45">30 - 45 minutes</MenuItem>
        //                     <MenuItem value="45 - 60">45 - 60 minutes</MenuItem>
        //                     <MenuItem value="More than 60">More than 60 minutes</MenuItem>
        //                 </Select>
        //             </FormControl>
        //         );
        //     }
        // },

        { field: 'orderInstruction', headerName: 'Order Instruction', width: 220 },
    ];
    return (
        <>
            {data.map((order) => (
                <div className="modal fade" id={`bootstrapModal${order.id}`} tabIndex="-1" aria-labelledby={`bootstrapModalLabel${order.id}`} aria-hidden="true" key={`bootstrap${order.id}`}>
                    <div className="modal-dialog">
                        <div className="modal-content" style={{ borderRadius: '15px', borderTop: '3px solid #f37a27', borderBottom: '3px solid #f37a27' }}>
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-start text-black pt-0 pb-0">
                                <h5 className="modal-title text-uppercase text-center mb-1" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>{order.shopName}</h5>
                                <h6 className="modal-title text-center mb-0" id="bootstrapModal" style={{ fontWeight: 'lighter' }}>GSTIN: {order.BusinessGSTIN}</h6>
                                <h6 className="modal-title text-center mb-0" id="bootstrapModal" style={{ fontWeight: 'lighter' }}>Phone No: +91 {order.shopPhone}</h6>
                                <hr />
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order ID</p>
                                        <p>{order.id}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Placed Time</p>
                                        <p>{new Intl.DateTimeFormat('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        }).format(new Date(order.timestamp.seconds * 1000))}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">User Name</p>
                                        <p>{order.userName}</p>
                                    </div>
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Payment ID</p>
                                        <p>{order.paymentId}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    {order.OrderType === 'Delivery' ? (<div className="col mb-1">
                                        <p className="small text-muted mb-1">Delivery Address</p>
                                        <p className="mb-0">{order.userAddress}</p>
                                        <p>{order.userCampus}</p>
                                    </div>) : (<div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Type</p>
                                        <p>{order.OrderType}</p>
                                    </div>)}
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Phone Number</p>
                                        <p>+91 {order.userPhone}</p>
                                    </div>
                                </div>
                                {order.orderInstruction ? (<div className="row">
                                    <div className="col mb-1">
                                        <p className="small text-muted mb-1">Order Instruction</p>
                                        <p>{order.orderInstruction}</p>
                                    </div>
                                </div>) : null}
                                <h4 className="mt-2 mb-2" style={{ color: "#35558a" }}>Payment Summary</h4>
                                <hr className="mt-2 mb-4" style={{ height: "0", backgroundColor: "transparent", opacity: ".75", bordertop: "2px dashed #9e9e9e" }} />
                                <div>
                                    {order.products && order.products.map(product => (
                                        <div className="d-flex justify-content-between" key={product.productId}>
                                            <div>
                                                <p className="fw-bold mb-0">{product.name} (Qty: {product.quantity})</p>
                                                <h6>{product.subtitle}</h6>
                                            </div>
                                            <p className="mb-0">&#8377;{product.price * product.quantity}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex justify-content-between mt-2">
                                    <h6 className="fw-bold">Sub Total</h6>
                                    <p className="lead fw-bold mb-0" style={{ fontWeight: 'bold', fontSize: '18px' }}>&#8377;{order.itemSubtotal.toFixed(2)}</p>
                                </div>
                                <hr className="mt-1" />

                                {parseFloat(order.shopDeliveryCut) > 0 ? (
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0">Delivery Charges</p>
                                        <p className="mb-0">&#8377;{order.shopDeliveryCut}</p>
                                    </div>) : null}

                                {parseFloat(order.packingFee) > 0 ? (
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0">Packing Charges</p>
                                        <p className="mb-0">&#8377;{order.packingFee}</p>
                                    </div>) : null}

                                {order.isGST ? (
                                    <>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">CGST (2.5%)</p>
                                            <p className="mb-0">&#8377;{(0.025 * order.itemSubtotal).toFixed(2)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">SGST (2.5%)</p>
                                            <p className="mb-0">&#8377;{(0.025 * order.itemSubtotal).toFixed(2)}</p>
                                        </div>
                                    </>) : null}

                                <div className="d-flex justify-content-between mt-2">
                                    <h5 className="fw-bold">Grand Total</h5>
                                    <p className="lead fw-bold mb-0" style={{ color: "#f37a27" }}>&#8377;{(order.itemSubtotal + (order.shopDeliveryCut ? parseFloat(order.shopDeliveryCut) : 0) + (order.isGST ? 0.05 * order.itemSubtotal : 0) + (order.packingFee ? parseFloat(order.packingFee) : 0)).toFixed(2)}</p>
                                </div>
                                <hr className="mt-1" />
                                <h6 className="modal-title text-center mb-1" id="bootstrapModal">FSSAI License Number: {order.FSSAI}</h6>
                                <h5 className="modal-title text-uppercase text-center mb-0" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>Thank You!!</h5>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm Status Change</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to change the status to {selectedStatus}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            const orderData = data.find(item => item.id === selectedOrderId);
                            if (orderData) {
                                updatePaymentStatus({
                                    orderId: selectedOrderId,
                                    orderStatus: selectedStatus,
                                    paymentId: orderData.paymentId,
                                    paisa: Math.round(parseFloat(orderData.totalAmount) * 100),
                                    transferPaisa: Math.round((orderData.itemSubtotal + (orderData.OrderType === 'Delivery' ? parseFloat(orderData.shopDeliveryCut) : 0) + (orderData.isGST ? 0.05 * orderData.itemSubtotal : 0) + (orderData.OrderType !== 'Dine-In' ? parseFloat(orderData.packingFee) : 0)) * 100),
                                    accountId: shop.routeId,
                                    isTest: orderData.isTest,
                                    isPremium: shop.isPremium
                                });
                            } else {
                                toast.error("Error updating Order Status. Order data not found for orderId:", selectedOrderId);
                            }
                            setIsDialogOpen(false);
                            setSelectedStatus('');
                            setSelectedOrderId(null);
                        }}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="datatable">
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                {isLoading && <Loader />}
                <div className="datatableTitle">
                    Active Orders
                </div>
                <div style={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        getRowHeight={() => 'auto'}
                        // getColumnHeaderHeight={() => 160}
                        autosizeOptions={{
                            columns: ['id', 'products', 'userAddress', 'userPhone'],
                            includeOutliers: true,
                            includeHeaders: false,
                        }}
                    // getRowHeight={({ id, densityFactor }) => {
                    //     return 100 * densityFactor;
                    // }}
                    />
                </div>
            </div>
        </>
    )
}
