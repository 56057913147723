import React, { useState, useEffect } from 'react';
import './style.css';
import Navbar from './Navbar';
import ActiveOrder from './ActiveOrder';
import Widget from '../Admin/Widget/Widget';
import Featured from '../Admin/Featured/Featured';
import Chart from '../Admin/Chart/Chart';
import { getDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../Config/Config';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Home() {
    const [productCount, setProductCount] = useState(0);
    const [pastOrderCount, setPastOrderCount] = useState(0);
    const [activeOrderCount, setActiveOrderCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [totalTodayRevenue, setTotalTodayRevenue] = useState(0);
    const [totalLastDayRevenue, setTotalLastDayRevenue] = useState(0);
    const [totalLastWeekRevenue, setTotalLastWeekRevenue] = useState(0);
    const [totalLastMonthRevenue, setTotalLastMonthRevenue] = useState(0);
    const [todayCancelledOrders, setTodayCancelledOrders] = useState(0);
    const [todayDeliveredOrders, setTodayDeliveredOrders] = useState(0);
    const [categoryOrderData, setCategoryOrderData] = useState([]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setIsLoading(true);
                fetchProductCount(user.uid);
                fetchOrdersAmountCalculation(user.uid);
            } else {
                setIsLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const calculateTotalAmountForLastNDays = (thirtyDaysPayment, days) => {
        if (thirtyDaysPayment.length > 0) {
            return thirtyDaysPayment
                .slice(-days)
                .reduce((totalAmount, entry) => totalAmount + (entry.amount || 0.0), 0);
        }
        return 0.0;
    };

    const fetchProductCount = async (uid) => {
        const userRef = doc(db, 'businessUsers', uid);
        const ordersCollectionRef = collection(db, 'Orders');
        try {
            const docSnapshot = await getDoc(userRef);
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                const products = userData.products || [];
                const totalActiveQuery = query(
                    ordersCollectionRef,
                    where('shopId', '==', uid),
                    where('status', 'in', ['Out for Delivery', 'In Progress', 'Ready for Pickup', ''])
                );
                const totalPastQuery = query(
                    ordersCollectionRef,
                    where('shopId', '==', uid),
                    where('status', 'in', ['Delivered', 'Cancelled'])
                );
                const totalActiveSnapshot = await getDocs(totalActiveQuery);
                const totalPastSnapshot = await getDocs(totalPastQuery);
                setProductCount(products.length);
                setPastOrderCount(totalPastSnapshot.size);
                setActiveOrderCount(totalActiveSnapshot.size);
                setUserCount(userData.users.length);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    };

    const fetchOrdersAmountCalculation = async (uid) => {
        const userRef = doc(db, 'businessUsers', uid);
        const ordersCollectionRef = collection(db, 'Orders');

        try {
            const docSnapshot = await getDoc(userRef);
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();

                let categoryCounts = {};
                let totalQuantity = 0;

                userData.ordersByCategory.forEach((product) => {
                    const category = product.category;
                    categoryCounts[category] = (categoryCounts[category] || 0) + product.quantity;
                    totalQuantity += product.quantity;
                });

                const categoryData = Object.entries(categoryCounts).map(([category, quantity]) => {
                    return {
                        category: category,
                        percentage: ((quantity / totalQuantity) * 100).toFixed(2)
                    };
                });

                setCategoryOrderData(categoryData);

                // Calculate today's revenue
                const today = new Date();
                const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                const todayEnd = new Date(todayStart);

                todayEnd.setDate(todayStart.getDate() + 1);

                const todayCancelledQuery = query(
                    ordersCollectionRef,
                    where('shopId', '==', uid),
                    where('status', '==', 'Cancelled'),
                    where('updatedAt', '>=', todayStart),
                    where('updatedAt', '<', todayEnd)
                );

                const todayCancelledOrdersSnapshot = await getDocs(todayCancelledQuery);
                const todayCancelledOrdersCount = todayCancelledOrdersSnapshot.size;
                setTodayCancelledOrders(todayCancelledOrdersCount);

                const todayOrdersQuery = query(
                    ordersCollectionRef,
                    where('shopId', '==', uid),
                    where('status', '==', 'Delivered'),
                    where('updatedAt', '>=', todayStart),
                    where('updatedAt', '<', todayEnd)
                );

                const todayOrdersSnapshot = await getDocs(todayOrdersQuery);
                const todayDeliveredOrdersCount = todayOrdersSnapshot.size;
                if (userData.thirtyDaysPayment && userData.thirtyDaysPayment.length >= 1) {
                    setTotalTodayRevenue(userData.thirtyDaysPayment[userData.thirtyDaysPayment.length - 1].amount);
                } else {
                    setTotalTodayRevenue(0);
                }
                setTodayDeliveredOrders(todayDeliveredOrdersCount);

                // Calculate last day's revenue
                if (userData.thirtyDaysPayment && userData.thirtyDaysPayment.length >= 2) {
                    setTotalLastDayRevenue(userData.thirtyDaysPayment[userData.thirtyDaysPayment.length - 2].amount);
                } else {
                    setTotalLastDayRevenue(0);
                }

                // Calculate last week's revenue
                setTotalLastWeekRevenue(calculateTotalAmountForLastNDays(userData.thirtyDaysPayment, 7));

                // Calculate last month's revenue
                setTotalLastMonthRevenue(calculateTotalAmountForLastNDays(userData.thirtyDaysPayment, 30));
            }
        } catch (error) {
            toast.error('Error fetching orders. Please Try After Sometime!!');
        }
    };

    return (
        <>
            <div className="home">
                <Navbar />
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                {isLoading && <Loader />}
                <div className="homeContainer">
                    <div className="widgets">
                        <Widget type="user" amount={userCount} isMoney={false} toLink='/business' />
                        <Widget type="activeorder" amount={activeOrderCount} isMoney={false} toLink='/business/order' />
                        <Widget type="pastorder" amount={pastOrderCount} isMoney={false} toLink='/business/transaction' />
                        <Widget type="earning" amount={totalTodayRevenue} isMoney={true} toLink='/business/transaction' />
                        <Widget type="product" amount={productCount} isMoney={false} toLink='/business/product' />
                    </div>
                    <div className="charts">
                        <Featured totalTodayRevenue={totalTodayRevenue} totalLastDayRevenue={totalLastDayRevenue} totalLastWeekRevenue={totalLastWeekRevenue} totalLastMonthRevenue={totalLastMonthRevenue} todayCancelledOrders={todayCancelledOrders} todayDeliveredOrders={todayDeliveredOrders} />
                        <Chart title="Category-Wise Percentage Sales" aspect={2 / 1} categoryOrderData={categoryOrderData} />
                    </div>
                    <div className="listContainer">
                        <ActiveOrder />
                    </div>
                </div>
            </div>
        </>
    )
}
