import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../Context/AuthContext'
import 'react-toastify/dist/ReactToastify.css';
import './style.css';

const AddToCart = ({ shopId, cart, handleInputChange, product }) => {
    const [buttonState, setButtonState] = useState('idle');
    const isProductInStock = product.status === 'Available';
    const { currentUser } = useContext(AuthContext);

    const handleClick = () => {
        if (isProductInStock) {
            if (currentUser && currentUser.userType === 'user') {
                setButtonState('adding');
                handleInputChange(shopId, product.id, (cart[shopId]?.[product.id] || 0) + 1);

                setTimeout(() => {
                    setButtonState('added');

                    setTimeout(() => {
                        setButtonState('idle');
                    }, 1000);
                }, 1000);
            } else {
                toast.info('Please log in as a user to add items to the cart.');
            }
        } else {
            toast.error('Product is out of stock. Cannot add to cart.');
        }
    };

    const removeFromCartHandler = () => {
        handleInputChange(shopId, product.id, (cart[shopId]?.[product.id] || 0) - 1);
    };

    return (
        <div className={`add-to-cart-container ${isProductInStock ? '' : 'out-of-stock'}`}>
            <button
                className={`add-to-cart-button ${isProductInStock ? '' : 'out-of-stock'} ${buttonState === 'adding' ? 'button--wait' : ''}`}
                onClick={handleClick}
                disabled={!isProductInStock || buttonState === 'adding'}
            >
                {buttonState === 'idle' && isProductInStock && 'Add to Bag'}
                {buttonState === 'adding' && 'Adding...'}
                {buttonState === 'added' && 'Added!'}
                {!isProductInStock && 'Out of Stock'}
                <span className={`button-icon icon-tocart ${buttonState === 'wait' ? 'button--wait' : ''}`} data-icon="tocart">
                    <svg width="14" height="24" viewBox="0 0 24 24">
                        <path d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z"></path>
                    </svg>
                </span>
            </button>

            {isProductInStock && (
                <div className="quantity-selector">
                    <button className="quantity-button" onClick={removeFromCartHandler} disabled={!isProductInStock}>
                        -
                    </button>
                    <span className="quantity-display">{cart[shopId]?.[product.id] || 0}</span>
                    <button className="quantity-button" onClick={() => handleClick()} disabled={!isProductInStock}>
                        +
                    </button>
                </div>
            )}
        </div>
    );
};

export default AddToCart;
