import React, { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from "@mui/x-data-grid";
import '../Shops/style.css';
import Sidebar from './Sidebar/Sidebar';
import { useParams } from 'react-router-dom';


export default function BusinessUserPrd() {

    const [data, setData] = useState([]);
    const [businessName, setBusinessName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { businessUserId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRef = doc(db, 'businessUsers', businessUserId);
                const unsub = onSnapshot(
                    userRef,
                    (docSnapshot) => {
                        if (docSnapshot.exists()) {
                            const userData = docSnapshot.data();
                            setBusinessName(userData.BusinessName);
                            const products = userData.products || [];
                            const formattedProducts = products.map(product => ({
                                id: product.Id,
                                Name: product.Name,
                                DietaryPreference: product.DietaryPreference,
                                Category: product.Category,
                                Price: product.Price,
                                status: product.status,
                                Customization: product.Customization,
                            }));
                            setData(formattedProducts);
                            setIsLoading(false);
                        } else {
                            setData([]);
                        }
                        setIsLoading(false);
                    },
                    (error) => {
                        toast.error(error.message);
                        setIsLoading(false);
                    }
                );
                return () => {
                    unsub();
                };
            } catch (error) {
                toast.error(error.message);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [businessUserId]);

    const columns = [
        {
            field: 'id',
            headerName: 'Product ID',
            width: 130,
            renderCell: (params) => {
                const productId = params.value;
                return (
                    <div
                        style={{ cursor: 'pointer', color: '#1976D2' }}
                        data-bs-toggle="modal"
                        data-bs-target={`#bootstrapModal${productId}`}
                    >
                        {productId}
                    </div>
                );
            },
        },

        {
            field: 'Name',
            headerName: 'Product Name',
            width: 200,
            renderCell: (params) => {
                const Name = params.value;
                return <div>{Name}</div>;
            }
        },

        {
            field: 'DietaryPreference',
            headerName: 'Dietary Pref',
            width: 150,
            renderCell: (params) => {
                const DietaryPreference = params.value;
                return <div>{DietaryPreference}</div>;
            }
        },

        {
            field: 'Category',
            headerName: 'Category',
            width: 170,
            renderCell: (params) => {
                const Category = params.value;
                return <div>{Category}</div>;
            }
        },


        {
            field: 'Price', headerName: 'Price', width: 130, renderCell: (params) => {
                const Price = params.value;
                return <div>{Price}</div>;
            }
        },

        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => {
                const status = params.value;
                return <div>{status}</div>;
            }
        },
    ];

    return (
        <>
            <div className="home">
                <Sidebar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <div className="datatable">
                            <ToastContainer
                                position="top-right"
                                autoClose={2400}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" />
                            {isLoading && <Loader />}
                            <div className="datatableTitle">
                                {businessName} Products
                            </div>
                            {data.length > 0 && data && data.map((product) => (
                                <div className="modal fade" id={`bootstrapModal${product.id}`} tabIndex="-1" aria-labelledby={`bootstrapModalLabel${product.id}`} aria-hidden="true" key={`bootstrap${product.id}`}>
                                    <div className="modal-dialog">
                                        <div className="modal-content" style={{ borderRadius: '15px', borderTop: '3px solid #f37a27', borderBottom: '3px solid #f37a27' }}>
                                            <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body text-start text-black p-3">
                                                <h5 className="modal-title text-uppercase text-center mb-4" id="bootstrapModal" style={{ color: "#f37a27", fontWeight: 'bold' }}>Product Summary</h5>
                                                <div className="row">
                                                    <div className="col mb-1">
                                                        <p className="small text-muted mb-1">Product ID</p>
                                                        <p>{product.id}</p>
                                                    </div>
                                                    <div className="col mb-1">
                                                        <p className="small text-muted mb-1">Product Name</p>
                                                        <p>{product.Name}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-1">
                                                        <p className="small text-muted mb-1">Price</p>
                                                        <p>&#8377;{product.Price}</p>
                                                    </div>
                                                    <div className="col mb-1">
                                                        <p className="small text-muted mb-1">Product Category</p>
                                                        <p>{product.Category}</p>
                                                    </div>
                                                </div>
                                                {product.Customization && product.Customization.length > 0 && (
                                                    <h4 className="mt-2 mb-2" style={{ color: "#35558a" }}>Customization Summary</h4>
                                                )}

                                                <hr className="mt-2 mb-4" style={{ height: "0", backgroundColor: "transparent", opacity: ".75", bordertop: "2px dashed #9e9e9e" }} />
                                                <div>
                                                    {product.Customization && product.Customization.some(item => item.customizationType === 'Size') && (
                                                        <>
                                                            <h5 className="mb-2" style={{ color: "#35558a" }}>Size Availables</h5>
                                                            {product.Customization
                                                                .filter(item => item.customizationType === 'Size' && item.customizationName)
                                                                .map(item => (
                                                                    <div className="d-flex justify-content-between" key={item.customizationName}>
                                                                        <p className="mb-0">{item.customizationName}</p>
                                                                        <p className="mb-0">+ &#8377;{item.customizationPrice}</p>
                                                                    </div>
                                                                ))}
                                                        </>
                                                    )}

                                                    {product.Customization && product.Customization.some(item => item.customizationType === 'Crust') && (
                                                        <>
                                                            <h5 className="mt-2 mb-2" style={{ color: "#35558a" }}>Choice of Crust</h5>
                                                            {product.Customization
                                                                .filter(item => item.customizationType === 'Crust' && item.customizationName)
                                                                .map(item => (
                                                                    <div className="d-flex justify-content-between" key={item.customizationName}>
                                                                        <p className="mb-0">{item.customizationName}</p>
                                                                        <p className="mb-0">+ &#8377;{item.customizationPrice}</p>
                                                                    </div>
                                                                ))}
                                                        </>
                                                    )}

                                                    {product.Customization && product.Customization.some(item => item.customizationType === 'Toppings') && (
                                                        <>
                                                            <h5 className="mt-2 mb-2" style={{ color: "#35558a" }}>Toppings</h5>
                                                            {product.Customization
                                                                .filter(item => item.customizationType === 'Toppings' && item.customizationName)
                                                                .map(item => (
                                                                    <div className="d-flex justify-content-between" key={item.customizationName}>
                                                                        <p className="mb-0">{item.customizationName}</p>
                                                                        <p className="mb-0">+ &#8377;{item.customizationPrice}</p>
                                                                    </div>
                                                                ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 10, page: 0, },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50]}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    getRowHeight={() => 'auto'}
                                    autosizeOptions={{
                                        columns: ['id', 'Name', 'Category', 'status'],
                                        includeOutliers: true,
                                        includeHeaders: false,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
