import React from 'react';
import './style.css';

export default function ShopDesp({ shop }) {
    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':');
        const formattedHours = (hours % 12) || 12;
        const period = hours < 12 ? 'AM' : 'PM';
        return `${formattedHours}:${minutes} ${period}`;
    };

    return (
        <>
            <div className="shop-info">
                <h1 className="shop-name">{shop.BusinessName}</h1>
                <div className="shop-rating">
                    <div className="star-rating" style={{ color: '#ffac00'}}>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <i
                                key={index}
                                className={`fas fa-star${index < Math.floor(shop.overallRating) ? '' : '-half-alt'}`}
                            ></i>
                        ))}
                    </div>
                    <span className="rating-count">{shop.noOfRatings || 0} Dining Reviews</span>
                </div>
                <span className="rating-count">Contact: +91 {shop.Phone}</span>
                <div className="shop-location">
                    <span className="location-icon"><i className="fas fa-map-marker-alt"></i></span>
                    <a href={shop.LocationLink} className="location-link">{shop.Location || 'Jodhpur, India'}</a>
                </div>
                {shop.StartTiming && shop.EndTiming && (
                    <span className="timing">
                        {convertTo12HourFormat(shop.StartTiming)} - {convertTo12HourFormat(shop.EndTiming)}
                    </span>
                )}
            </div>
        </>
    );
}
