import React from 'react'
import "./widget.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GroupIcon from '@mui/icons-material/Group';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Link } from "react-router-dom";


export default function Widget({ type, amount, isMoney, toLink }) {
    let data;


    switch (type) {
        case "user":
            data = {
                title: "ACTIVE USERS",
                isMoney: isMoney,
                link: "See Users Details",
                icon: (
                    <PersonOutlinedIcon
                        className="icon"
                        style={{
                            color: "crimson",
                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                        }}
                    />
                ),
            };
            break;
        case "pastorder":
            data = {
                title: "PAST ORDERS",
                isMoney: isMoney,
                link: "View all orders",
                icon: (
                    <MonetizationOnOutlinedIcon
                        className="icon"
                        style={{
                            backgroundColor: "rgba(8, 15, 32, 0.2)",
                            color: "blue",
                        }}
                    />
                ),
            };
            break;
        case "activeorder":
            data = {
                title: "ACTIVE ORDERS",
                isMoney: isMoney,
                link: "View all orders",
                icon: (
                    <ShoppingCartOutlinedIcon
                        className="icon"
                        style={{
                            backgroundColor: "rgba(218, 165, 32, 0.2)",
                            color: "goldenrod",
                        }}
                    />
                ),
            };
            break;
        case "earning":
            data = {
                title: "EARNINGS",
                isMoney: isMoney,
                link: "View Net Earnings",
                icon: (
                    <CurrencyRupeeIcon
                        className="icon"
                        style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
                    />
                ),
            };
            break;
        case "product":
            data = {
                title: "PRODUCTS",
                isMoney: isMoney,
                link: "See Product Details",
                icon: (
                    <AccountBalanceWalletOutlinedIcon
                        className="icon"
                        style={{
                            backgroundColor: "rgba(128, 0, 128, 0.2)",
                            color: "purple",
                        }}
                    />
                ),
            };
            break;
        case "perDayOrders":
            data = {
                title: "ORDERS PER DAY",
                isMoney: isMoney,
                link: "See Order Details",
                icon: (
                    <AccountBalanceWalletOutlinedIcon
                        className="icon"
                        style={{
                            backgroundColor: "rgba(169, 0, 169, 0.2)",
                            color: "purple",
                        }}
                    />
                ),
            };
            break;
        case "businessUser":
            data = {
                title: "BUSINESS USERS",
                isMoney: isMoney,
                link: "See Users Details",
                icon: (
                    <GroupIcon
                        className="icon"
                        style={{
                            backgroundColor: "rgba(128, 0, 128, 0.2)",
                            color: "purple",
                        }}
                    />
                ),
            };
            break;
        case "businessQuery":
            data = {
                title: "BUSINESS QUERY",
                isMoney: isMoney,
                link: "See Query Details",
                icon: (
                    <QuestionAnswerIcon
                        className="icon"
                        style={{
                            backgroundColor: "rgba(8, 15, 32, 0.2)",
                            color: "blue",
                        }}
                    />
                ),
            };
            break;
            case "userQuery":
                data = {
                    title: "USER QUERY",
                    isMoney: isMoney,
                    link: "See Query Details",
                    icon: (
                        <LiveHelpIcon
                            className="icon"
                            style={{
                                backgroundColor: "rgba(218, 165, 32, 0.2)",
                                color: "crimson",
                            }}
                        />
                    ),
                };
                break;
        default:
            break;
    }
    return (
        <>
            <div className="widget">
                <div className="left">
                    <span className="title">{data && data.title}</span>
                    <span className="counter">
                        {(data && data.isMoney) && "₹"} {amount}
                    </span>
                    <Link aria-current="page" to={toLink} >
                        <span className="link">{data && data.link}</span>
                    </Link>
                </div>
                <div className="right">
                    <div className="percentage positive">
                        <KeyboardArrowUpIcon />
                    </div>
                    {data && data.icon}
                </div>
            </div>
        </>
    )
}
