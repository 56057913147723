import React, { useEffect, useState } from 'react';
import { collection, doc, updateDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { auth, db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from "@mui/x-data-grid";
import '../Shops/style.css';
import Sidebar from './Sidebar/Sidebar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function QueryList({ userDB, userDbSource }) {

    const [queryUsers, setQueryUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedQueryId, setSelectedQueryId] = useState(null);


    const handleStatusChange = async (queryId, status) => {
        try {
            setSelectedStatus(status);
            setSelectedQueryId(queryId);
            setIsDialogOpen(true);
            await updateQueryStatus(queryId, status);
        }
        catch (error) {
            toast.error("Error updating Order Status. Please Contact the Administrator or Try after Sometime!!");
        }
    };

    const updateQueryStatus = async (queryId, status) => {
        setIsLoading(true);
        try {
            const queryRef = doc(db, userDB, queryId);
            await updateDoc(queryRef, {
                status: status,
                updatedAt: serverTimestamp()
            });
        } catch (error) {
            toast.error("Error updating Order Status. Please Contact the Administrator or Try after Sometime!!");
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const unsubscribeAuthStateChange = auth.onAuthStateChanged((user) => {
            if (user) {
                const currentUser = auth.currentUser;

                if (!currentUser) {
                    return;
                }

                setIsLoading(true);
                const queryUsersCollectionRef = collection(db, userDB);

                const unsubscribe = onSnapshot(queryUsersCollectionRef, (querySnapshot) => {
                    const queryUsersData = [];
                    querySnapshot.forEach((doc) => {
                        queryUsersData.push({
                            id: doc.id,
                            ...doc.data(),
                        });
                    });
                    setQueryUsers(queryUsersData);
                    setIsLoading(false);
                });

                return () => {
                    unsubscribe();
                };
            } else {
                setIsLoading(false);
                setQueryUsers([]);
            }
        });

        return () => {
            unsubscribeAuthStateChange();
        };
    }, [userDB]);

    const CommonColumns = [
        {
            field: 'status',
            headerName: 'Query Status',
            width: 180,
            renderCell: (params) => {
                const queryStatus = params.value || { seconds: 0 };;
                return (
                    <FormControl variant="outlined" size="small" margin='normal' fullWidth>
                        <InputLabel>Select Status</InputLabel>
                        <Select
                            value={queryStatus}
                            onChange={(event) => {
                                handleStatusChange(params.row.id, event.target.value);
                            }}
                            label="Select Status"
                        >
                            <MenuItem value={null} disabled>Select Status</MenuItem>
                            <MenuItem value="In Progress">In Progress</MenuItem>
                            <MenuItem value="Resolved">Resolved</MenuItem>
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            width: 200,
            renderCell: (params) => {
                const timestamp = params.value?.toDate();
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };
                const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(timestamp);
                return <div>{formattedTimestamp}</div>;
            }
        },
        {
            field: 'updatedAt',
            headerName: 'Updated Status',
            width: 200,
            renderCell: (params) => {
                if (params.value) {
                    const timestamp = params.value?.toDate();
                    const options = {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true
                    };
                    const formattedTimestamp = new Intl.DateTimeFormat('en-US', options).format(timestamp);
                    return <div>{formattedTimestamp}</div>;
                }
                return <div>No Update</div>;
            }
        },
    ]


    return (
        <>
            <div className="home">
                <Sidebar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <Dialog
                            open={isDialogOpen}
                            onClose={() => setIsDialogOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Confirm Status Change</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to change the status to {selectedStatus}?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setIsDialogOpen(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        updateQueryStatus(selectedQueryId, selectedStatus);
                                        setIsDialogOpen(false);
                                        setSelectedStatus('');
                                        setSelectedQueryId(null);
                                    }}
                                    color="primary"
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div className="datatable">
                            <ToastContainer
                                position="top-right"
                                autoClose={2400}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light" />
                            {isLoading && <Loader />}
                            <div className="datatableTitle">
                                Query Details
                            </div>
                            <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={queryUsers}
                                    columns={userDbSource.concat(CommonColumns)}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 10, page: 0 },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50]}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    getRowHeight={() => 'auto'}
                                    autosizeOptions={{
                                        columns: ['id', 'products', 'userAddress', 'userPhone'],
                                        includeOutliers: true,
                                        includeHeaders: false,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
