import React, { useState, useRef } from 'react';
import { auth, db } from "../../Config/Config";
import { useNavigate } from "react-router-dom";
import { InputLabel, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import Navbar from './Navbar';
import { doc, updateDoc, getDoc } from "firebase/firestore";


export default function AddNewUser() {
    const [businessEmail, setBusinessEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const scrollRef = useRef();

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setPasswordsMatch(confirmPassword === newPassword);
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordsMatch(password === newConfirmPassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setPasswordsMatch(false);
            return;
        }
        setIsLoading(true);
        try {
            const currentUser = auth.currentUser;
            const currentUserEmail = currentUser.email;
            if (currentUser) {
                const credentials = await auth.createUserWithEmailAndPassword(businessEmail, password);
                await credentials.user.sendEmailVerification();

                const userPasswordPromise = new Promise((resolve, reject) => {
                    const userPassword = prompt("Please enter your password to confirm");
                    if (userPassword) {
                        resolve(userPassword);
                    } else {
                        reject(new Error("Password input was canceled."));
                    }
                });

                const userPassword = await userPasswordPromise;

                await auth.signInWithEmailAndPassword(currentUserEmail, userPassword);
                const uid = auth.currentUser.uid;
                const shopRef = doc(db, 'businessUsers', uid);
                const shopSnapshot = await getDoc(shopRef);
                const usersList = shopSnapshot.get('manageUsers') || [];
                usersList.push({
                    uid: credentials.user.uid,
                    email: businessEmail,
                });
                updateDoc(shopRef, { manageUsers: usersList });
                await db.collection("businessAdminUsers").doc(credentials.user.uid).set({
                    shopUid: auth.currentUser.uid,
                });
            }

            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            toast.success("User Added Successful. Please check your email for verification.");
            setBusinessEmail("");
            setPassword("");
            setConfirmPassword("");
            setIsLoading(false);

            setTimeout(() => {
                navigate('/business/manageUsers');
            }, 2500);
        } catch (error) {
            toast.error(error.message);
            setIsLoading(false);
        }
    };


    return (
        <>
            <div ref={scrollRef}></div>
            <div className="home">
                <Navbar />
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                <div className="homeContainer">
                    <div className="container-fluid px-1 py-5 mx-auto">
                        <div className="row d-flex justify-content-center">
                            <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center" style={{ paddingTop: '20px', width: '62%' }}>
                                <div className="prdcard">
                                    <h1>Add New User</h1>
                                    <form className="form-card" onSubmit={handleSubmit}>
                                        <div className="row justify-content-center">
                                            <div className="form-group col-lg-10 flex-column d-flex mt-4">
                                                <InputLabel htmlFor="PrdName" className="form-control-label px-3">
                                                    E-mail ID<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <TextField
                                                    type="email"
                                                    id="EmailID"
                                                    name="EmailID"
                                                    placeholder="Enter User's E-mail ID"
                                                    onChange={(e) => setBusinessEmail(e.target.value)}
                                                    value={businessEmail}
                                                    variant="outlined"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="form-group col-lg-10 flex-column d-flex mt-4">
                                                <InputLabel htmlFor="PrdName" className="form-control-label px-3">
                                                    Password<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <TextField
                                                    type="password"
                                                    id="password"
                                                    name="Password"
                                                    className={`form-control ${passwordsMatch ? "" : "is-invalid"}`}
                                                    placeholder="Enter the Password"
                                                    onChange={handlePasswordChange}
                                                    value={password}
                                                    variant="outlined"
                                                    required
                                                />
                                                {!passwordsMatch && (
                                                    <div className="invalid-feedback">Passwords do not match.</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="form-group col-lg-10 flex-column d-flex mt-4">
                                                <InputLabel htmlFor="PrdName" className="form-control-label px-3">
                                                    Confirm Password<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <TextField
                                                    type="password"
                                                    id="confirmPass"
                                                    name="Confirm Password"
                                                    className={`form-control ${passwordsMatch ? "" : "is-invalid"}`}
                                                    placeholder="Enter the Password"
                                                    onChange={handleConfirmPasswordChange}
                                                    value={confirmPassword}
                                                    variant="outlined"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-4">
                                            <div className="form-group col-sm-6"> <button type="submit" className="btn-block btn-primary">Submit</button> </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
        </>
    )
}
