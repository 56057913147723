import React, { useState, useEffect } from 'react'
import "../Users/style.css";
import './Navbar';
import './style.css'
import { auth, db } from "../../Config/Config";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { doc, serverTimestamp, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Navbar from './Navbar';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


export default function Profile() {
    const [user, setUser] = useState(null);
    const [data, setData] = useState({
        Phone: '',
        ServiceAvailable: [],
        modeOfDelivery: [],
        shopCategory: [],
        productCategory: [],
    });
    const [selectedStartTime, setSelectedStartTime] = useState('');
    const [selectedEndTime, setSelectedEndTime] = useState('');

    const [passwordData, setPasswordData] = useState({
        newPassword: '',
        renewPassword: ''
    });
    const [reauthPassword, setReauthPassword] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [selectedColleges, setSelectedColleges] = useState([]);
    const [selectedMode, setSelectedMode] = useState([]);
    const [selectedShopCategory, setSelectedShopCategory] = useState([]);
    const [selectedPrdCategory, setSelectedPrdCategory] = useState([]);


    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                const uid = currentUser.uid;
                const userRef = doc(db, 'businessUsers', uid);

                const fetchData = async () => {
                    setIsLoading(true);
                    try {
                        const docSnapshot = await getDoc(userRef);

                        if (docSnapshot.exists()) {
                            const userData = docSnapshot.data();
                            setUser(userData);
                            setData({
                                Phone: userData.Phone || '',
                                ServiceAvailable: userData.ServiceAvailable || [],
                                modeOfDelivery: userData.modeOfDelivery || [],
                                shopCategory: userData.shopCategory || [],
                                productCategory: userData.productCategory || [],
                            });
                            setSelectedStartTime(userData.StartTiming || '');
                            setSelectedEndTime(userData.EndTiming || '')
                            setSelectedColleges(userData.ServiceAvailable || []);
                            setSelectedMode(userData.modeOfDelivery || []);
                            setSelectedShopCategory(userData.shopCategory || []);
                            setSelectedPrdCategory(userData.productCategory || []);
                        } else {
                            setUser({
                                FirstName: '',
                                LastName: '',
                                BusinessEmail: '',
                                BusinessGSTIN: '',
                                BusinessName: '',
                                Phone: '',
                                StartTiming: '',
                                EndTiming: '',
                                ServiceAvailable: [],
                                modeOfDelivery: [],
                                shopCategory: [],
                                productCategory: [],
                            });
                        }
                    } catch (error) {
                        toast.error(error.message);
                    } finally {
                        setIsLoading(false);
                    }
                };
                fetchData();
            } else {
                setUser(null);
                setIsLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const handleEditProfileSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const currentUser = auth.currentUser;

        if (currentUser) {
            const uid = currentUser.uid;
            const userRef = doc(db, 'businessUsers', uid);

            try {
                await updateDoc(userRef, {
                    ...data,
                    userType: 'businessUser',
                    timeStamp: serverTimestamp(),
                });
                toast.success(
                    'Successfully Updated. You will now automatically get redirected to the Home page'
                );
                setTimeout(() => {
                    navigate('/business');
                }, 2500);
            } catch (err) {
                toast.error(err.message);
            }
        }
        setIsLoading(false);
    };

    const handleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        setData({ ...data, [id]: value });
    };

    const handleServiceAvailableChange = (event) => {
        const selectedServices = event.target.value;
        setSelectedColleges(selectedServices);
        setData({ ...data, ServiceAvailable: selectedServices });
    };

    const handleModeAvailableChange = (event) => {
        const selectedModes = event.target.value;
        setSelectedMode(selectedModes);
        setData({ ...data, modeOfDelivery: selectedModes });
    };

    const handleShopCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setSelectedShopCategory(selectedCategory);
        setData({ ...data, shopCategory: selectedCategory });
    };

    const handlePrdCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setSelectedPrdCategory(selectedCategory);
        setData({ ...data, productCategory: selectedCategory });
    };

    const handleStartTimingChange = (event) => {
        const StartTiming = event.target.value;
        setSelectedStartTime(StartTiming);
        setData({ ...data, StartTiming: StartTiming });
    };

    const handleEndTimingChange = (event) => {
        const EndTiming = event.target.value;
        setSelectedEndTime(EndTiming);
        setData({ ...data, EndTiming: EndTiming });
    };

    const handlePasswordChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        setPasswordData({ ...passwordData, [id]: value });
    };

    const generateHoursArray = () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            const hour = i.toString().padStart(2, '0');
            hours.push(`${hour}:00`);
        }
        return hours;
    };

    const convertTo12HourFormat = (time) => {
        if (!time || typeof time !== 'string' || !/^\d{2}:\d{2}$/.test(time)) {
            return '';
        }
        const [hours, minutes] = time.split(':');
        const formattedHours = (hours % 12) || 12;
        const period = hours < 12 ? 'AM' : 'PM';
        return `${formattedHours}:${minutes} ${period}`;
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        const { newPassword, renewPassword } = passwordData;

        if (newPassword !== renewPassword) {
            toast.error("The entered new passwords do not match!");
            setPasswordData({
                newPassword: '',
                renewPassword: ''
            })
            setReauthPassword('')
            return;
        }

        if (reauthPassword === newPassword) {
            toast.info("Your password remains unchanged as it matches your current password!");
            setPasswordData({
                newPassword: '',
                renewPassword: ''
            })
            setReauthPassword('')
            return;
        }

        try {
            const credential = EmailAuthProvider.credential(auth.currentUser.email, reauthPassword);
            await reauthenticateWithCredential(auth.currentUser, credential);

            await auth.currentUser.updatePassword(newPassword);

            toast.success("Your password has been successfully updated!");
            setPasswordData({
                newPassword: '',
                renewPassword: ''
            })
            setReauthPassword('')
            setTimeout(() => {
                navigate('/business');
            }, 1000);
        } catch (error) {
            toast.error("Current Password is wrong!");
            setPasswordData({
                newPassword: '',
                renewPassword: ''
            })
            setReauthPassword('')
        }
    };

    let fullName;
    if (user) {
        const firstName = user.FirstName.charAt(0).toUpperCase() + user.FirstName.slice(1).toLowerCase();
        const lastName = user.LastName.charAt(0).toUpperCase() + user.LastName.slice(1).toLowerCase();
        fullName = firstName + ' ' + lastName;
    }
    return (
        <>
            <section className="section profile" style={{ padding: '3px 0' }}>
                {isLoading && <Loader />}
                <div className="home">
                    <Navbar />
                    <ToastContainer
                        position="top-right"
                        autoClose={2400}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light" />
                    <div className="homeContainer">
                        <div className="row">
                            <div className="col-xl-8" style={{ margin: 'auto' }}>
                                <div className="card" style={{ marginTop: '50px', padding: '10px 20px 20px' }}>
                                    <div className="card-body pt-3">
                                        <ul className="nav nav-tabs nav-tabs-bordered">

                                            <li className="nav-item">
                                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                                            </li>

                                            <li className="nav-item">
                                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                                            </li>

                                            <li className="nav-item">
                                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                                            </li>

                                        </ul>
                                        <div className="tab-content pt-2">

                                            <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                                <h5 className="card-title">Business Name</h5>
                                                <p className="large fst-italic">{user ? user.BusinessName : ''}</p>

                                                <h5 className="card-title">Profile Details</h5>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label ">Full Name</div>
                                                    <div className="col-lg-9 col-md-8">{user ? fullName : ''}</div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label">Business GSTIN</div>
                                                    <div className="col-lg-9 col-md-8">{user ? user.BusinessGSTIN : ''}</div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label">Phone</div>
                                                    <div className="col-lg-9 col-md-8">{user ? user.Phone : ''}</div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label">Email</div>
                                                    <div className="col-lg-9 col-md-8">{user ? user.BusinessEmail : ''}</div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label ">
                                                        Mode(s) Available
                                                    </div>
                                                    <div className="col-lg-9 col-md-8">
                                                        {data.modeOfDelivery.join(', ')}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label ">
                                                        Shop Category(s)
                                                    </div>
                                                    <div className="col-lg-9 col-md-8">
                                                        {data.shopCategory.join(', ')}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label ">
                                                        Product Category(s)
                                                    </div>
                                                    <div className="col-lg-9 col-md-8">
                                                        {data.productCategory.join(', ')}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label ">
                                                        Service(s) Available at
                                                    </div>
                                                    <div className="col-lg-9 col-md-8">
                                                        {data.ServiceAvailable.join(', ')}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-4 label">Shop Timing</div>
                                                    <div className="col-lg-9 col-md-8">{user ? `${convertTo12HourFormat(user.StartTiming)} - ${convertTo12HourFormat(user.EndTiming)}` : ''}</div>
                                                </div>
                                            </div>

                                            <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                                                <form onSubmit={handleEditProfileSubmit}>
                                                    <div className="row mb-3">
                                                        <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <input name="fullName" type="text" className="form-control" id="fullName" value={user ? fullName : ''} readOnly />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="businessGSTIN" className="col-md-4 col-lg-3 col-form-label">Business GSTIN</label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <input name="businessGSTIN" type="text" className="form-control" id="businessGSTIN" value={user ? user.BusinessGSTIN : ''} readOnly />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="businessName" className="col-md-4 col-lg-3 col-form-label">Business Name</label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <input name="businessName" type="text" className="form-control" id="businessName" value={user ? user.BusinessName : ''} readOnly />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">Phone<span className="required-field" style={{ color: "var(--color-primary)" }}>*</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <input name="phone" type="text" className="form-control" id="Phone" value={data.Phone} onChange={handleChange} required />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <input name="email" type="email" className="form-control" id="Email" value={user ? user.BusinessEmail : ''} readOnly />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="Timing" className="col-md-4 col-lg-3 col-form-label">Shop Timing<span className="required-field" style={{ color: "var(--color-primary)" }}>*</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <FormControl className="form-outline mb-4 flex-column d-flex">
                                                                        <InputLabel id="startTime-label">Start Time</InputLabel>
                                                                        <Select
                                                                            labelId="startTime-label"
                                                                            id="StartTiming"
                                                                            name="StartTiming"
                                                                            value={selectedStartTime}
                                                                            onChange={handleStartTimingChange}
                                                                            label="Start Time"
                                                                        >
                                                                            {generateHoursArray().map((hour) => (
                                                                                <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FormControl className="form-outline mb-4 flex-column d-flex">
                                                                        <InputLabel id="endTime-label">End Time</InputLabel>
                                                                        <Select
                                                                            labelId="endTime-label"
                                                                            id="EndTiming"
                                                                            name="EndTiming"
                                                                            value={selectedEndTime}
                                                                            onChange={handleEndTimingChange}
                                                                            label="End Time"
                                                                        >
                                                                            {generateHoursArray().map((hour) => (
                                                                                <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label className="col-md-4 col-lg-3 col-form-label" htmlFor="mode">Select the mode(s) for which your service is available <span className="required-field" style={{ color: "var(--color-primary)" }}>*</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <FormControl className="form-outline mb-4 flex-column d-flex">
                                                                <InputLabel id="college-label">Select Mode(s)</InputLabel>
                                                                <Select
                                                                    labelId="mode-label"
                                                                    id="Mode"
                                                                    name="Mode"
                                                                    required
                                                                    multiple
                                                                    value={selectedMode}
                                                                    onChange={handleModeAvailableChange}
                                                                    label="Select College(s)"
                                                                >
                                                                    <MenuItem value="" disabled>Select Mode(s)</MenuItem>
                                                                    <MenuItem value="Dine-In">Dine-In</MenuItem>
                                                                    <MenuItem value="Takeaway">Takeaway</MenuItem>
                                                                    <MenuItem value="Delivery">Delivery</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label className="col-md-4 col-lg-3 col-form-label" htmlFor="mode">Select the shop category(s) <span className="required-field" style={{ color: "var(--color-primary)" }}>*</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <FormControl className="form-outline mb-4 flex-column d-flex">
                                                                <InputLabel id="college-label">Select Shop Category(s)</InputLabel>
                                                                <Select
                                                                    labelId="shop-label"
                                                                    id="shopCategory"
                                                                    name="shopCategory"
                                                                    required
                                                                    multiple
                                                                    value={selectedShopCategory}
                                                                    onChange={handleShopCategoryChange}
                                                                    label="Select Shop Category(s)"
                                                                >
                                                                    <MenuItem value="" disabled>Select Shop Category(s)</MenuItem>
                                                                    <MenuItem value="Food">Food</MenuItem>
                                                                    <MenuItem value="Grocery">Grocery</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label className="col-md-4 col-lg-3 col-form-label" htmlFor="category">Select the product category(s) <span className="required-field" style={{ color: "var(--color-primary)" }}>*</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <FormControl className="form-outline mb-4 flex-column d-flex">
                                                                <InputLabel id="category-label">Select Product Category(s)</InputLabel>
                                                                <Select
                                                                    labelId="category-label"
                                                                    id="Category"
                                                                    name="Category"
                                                                    required
                                                                    multiple
                                                                    value={selectedPrdCategory}
                                                                    onChange={handlePrdCategoryChange}
                                                                    label="Select Product Category(s)"
                                                                >
                                                                    <MenuItem value="" disabled>Select Product Category(s)</MenuItem>
                                                                    <MenuItem value="Italian">Italian</MenuItem>
                                                                    <MenuItem value="Starters">Starters</MenuItem>
                                                                    <MenuItem value="Chinese">Chinese</MenuItem>
                                                                    <MenuItem value="Indian Main Course">Indian Main Course</MenuItem>
                                                                    <MenuItem value="Rolls & Breads">Rolls & Breads</MenuItem>
                                                                    <MenuItem value="South Indian">South Indian</MenuItem>
                                                                    <MenuItem value="Snacks">Snacks</MenuItem>
                                                                    <MenuItem value="Beverages">Beverages</MenuItem>
                                                                    <MenuItem value="Kitchen & Household Essentials">Kitchen & Household Essentials</MenuItem>
                                                                    <MenuItem value="Personal Care">Personal Care</MenuItem>
                                                                    <MenuItem value="Bakery & Confectionary">Bakery & Confectionary</MenuItem>
                                                                    <MenuItem value="Dairy & Eggs">Dairy & Eggs</MenuItem>
                                                                    <MenuItem value="Others">Others</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label className="col-md-4 col-lg-3 col-form-label" htmlFor="college">Select the college(s) where your service is available <span className="required-field" style={{ color: "var(--color-primary)" }}>*</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <FormControl className="form-outline mb-4 flex-column d-flex">
                                                                <InputLabel id="college-label">Select College(s)</InputLabel>
                                                                <Select
                                                                    labelId="college-label"
                                                                    id="College"
                                                                    name="College"
                                                                    required
                                                                    multiple
                                                                    value={selectedColleges}
                                                                    onChange={handleServiceAvailableChange}
                                                                    label="Select College(s)"
                                                                >
                                                                    <MenuItem value="" disabled>Select College(s)</MenuItem>
                                                                    <MenuItem value="IIT Jodhpur">IIT Jodhpur</MenuItem>
                                                                    <MenuItem value="NIFT Jodhpur">NIFT Jodhpur</MenuItem>
                                                                    <MenuItem value="Ayurved University">Ayurved University Jodhpur</MenuItem>
                                                                    <MenuItem value="NLU Jodhpur">NLU Jodhpur</MenuItem>
                                                                    <MenuItem value="SPUP">Sardar Patel University of Police Jodhpur</MenuItem>
                                                                    <MenuItem value="Agriculture University">Agriculture University</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <button type="submit" className="btn btn-primary">Save Changes</button>
                                                    </div>
                                                </form>
                                            </div>

                                            <div className="tab-pane fade pt-3" id="profile-change-password">
                                                <form onSubmit={handleChangePassword}>
                                                    <div className="row mb-3">
                                                        <label htmlFor="currentPassword" className="col-md-4 col-lg-3 col-form-label">Current Password<span className="text-danger"> *</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <input
                                                                name="reauthPassword"
                                                                type="password"
                                                                className="form-control"
                                                                id="reauthPassword"
                                                                autoComplete="current-password"
                                                                value={reauthPassword}
                                                                onChange={(e) => setReauthPassword(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">New Password<span className="text-danger"> *</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <input
                                                                name="newPassword"
                                                                type="password"
                                                                className="form-control"
                                                                id="newPassword"
                                                                autoComplete="new-password"
                                                                value={passwordData.newPassword}
                                                                onChange={handlePasswordChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label">Re-enter New Password<span className="text-danger"> *</span></label>
                                                        <div className="col-md-8 col-lg-9">
                                                            <input
                                                                name="renewPassword"
                                                                type="password"
                                                                className="form-control"
                                                                id="renewPassword"
                                                                autoComplete="renew-password"
                                                                value={passwordData.renewPassword}
                                                                onChange={handlePasswordChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="text-center">
                                                        <button type="submit" className="btn btn-primary">Change Password</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
