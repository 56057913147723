import { Link } from "react-router-dom";

export const userQueryColumns = [
    {
        field: 'id',
        headerName: 'Query ID',
        width: 200,
        renderCell: (params) => {
            const queryUserId = params.value;
            return (
                <div>
                    <Link
                        to={`/admin/userQuery/${queryUserId}`}
                        style={{
                            color: '#007bff',
                            transition: 'color 0.3s',
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0056b3'}
                        onMouseLeave={(e) => e.target.style.color = '#007bff'}
                    >
                        {queryUserId}
                    </Link>


                </div>
            );
        },
    },

    {
        field: 'userID',
        headerName: 'User ID',
        width: 250,
        renderCell: (params) => {
            const userID = params.value;
            return <div>{userID}</div>;
        }
    },

    {
        field: 'name',
        headerName: 'User Name',
        width: 130,
        renderCell: (params) => {
            const userName = params.value;
            return <div>{userName}</div>;
        }
    },

    {
        field: 'email',
        headerName: 'User Email',
        width: 200,
        renderCell: (params) => {
            const userEmail = params.value;
            return <div>{userEmail}</div>;
        }
    },


    {
        field: 'phoneNumber', headerName: 'Contact', width: 130, renderCell: (params) => {
            const phoneNumber = params.value || '';
            const formattedPhoneNumber = `+91 ${phoneNumber}`;
            return <div>{formattedPhoneNumber}</div>;
        }
    },

];