import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../Config/Config";
import { serverTimestamp } from "firebase/firestore";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


export default function BusinessRegister() {
    const navigate = useNavigate();

    const [businessGSTIN, setBusinessGSTIN] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [businessEmail, setBusinessEmail] = useState("");
    const [password, setPassword] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const [selectedColleges, setSelectedColleges] = useState([]);
    const [selectedModeOfDelivery, setSelectedModeOfDelivery] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedShopCategory, setSelectedShopCategory] = useState([]);

    const scrollRef = useRef();

    const handleCollegeChange = (event) => {
        setSelectedColleges(event.target.value);
    };

    const handleModeOfDeliveryChange = (event) => {
        setSelectedModeOfDelivery(event.target.value);
    };

    const handleShopCategoryChange = (event) => {
        setSelectedShopCategory(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setPasswordsMatch(confirmPassword === newPassword);
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordsMatch(password === newConfirmPassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setPasswordsMatch(false);
            return;
        }

        try {
            const credentials = await auth.createUserWithEmailAndPassword(businessEmail, password);
            await credentials.user.sendEmailVerification();

            await db.collection("businessUsers").doc(credentials.user.uid).set({
                BusinessGSTIN: businessGSTIN,
                BusinessName: businessName,
                FirstName: firstName,
                LastName: lastName,
                BusinessEmail: businessEmail,
                ServiceAvailable: selectedColleges,
                modeOfDelivery: selectedModeOfDelivery,
                shopCategory: selectedShopCategory,
                productCategory: selectedCategory,
                userType: 'businessUser',
                ShopStatus: 'Open',
                UserStatus: 'In Progress',
                Phone: '',
                users: [],
                thirtyDaysPayment: [],
                ordersByCategory: [],
                timeStamp: serverTimestamp(),
            });

            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            toast.success("Signup Successful. Please check your email for verification. You will be redirected to Login page!!");
            setBusinessGSTIN("");
            setBusinessName("");
            setFirstName("");
            setLastName("");
            setBusinessEmail("");
            setPassword("");
            setConfirmPassword("");
            setSelectedColleges([]);
            setSelectedModeOfDelivery([]);
            setSelectedShopCategory([]);
            setSelectedCategory([]);;

            setTimeout(() => {
                navigate("/login");
            }, 3000);
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <>
            <div ref={scrollRef}></div>
            <div className="form-outline mb-4">
                <input
                    type="text"
                    id="businessGSTIN"
                    className="form-control"
                    onChange={(e) => setBusinessGSTIN(e.target.value)}
                    value={businessGSTIN}
                />
                <label className="form-label" htmlFor="businessGSTIN">
                    Business GSTIN
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
            </div>
            <div className="form-outline mb-4">
                <input
                    type="text"
                    id="businessName"
                    className="form-control"
                    required
                    onChange={(e) => setBusinessName(e.target.value)}
                    value={businessName}
                />
                <label className="form-label" htmlFor="businessName">
                    Business Name
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
            </div>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <div className="form-outline">
                        <input
                            type="text"
                            id="businessUserFirst"
                            className="form-control"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                        />
                        <label className="form-label" htmlFor="businessUserFirst">
                            First Name
                            <span
                                className="required-field"
                                style={{ color: "var(--color-primary)" }}
                            >
                                *
                            </span>
                        </label>
                    </div>
                </div>
                <div className="col-md-6 mb-4">
                    <div className="form-outline">
                        <input
                            type="text"
                            id="businessUserLast"
                            className="form-control"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                        />
                        <label className="form-label" htmlFor="businessUserLast">
                            Last Name
                            <span
                                className="required-field"
                                style={{ color: "var(--color-primary)" }}
                            >
                                *
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            <div className="form-outline mb-4">
                <input
                    type="email"
                    id="businessEmail"
                    className="form-control"
                    onChange={(e) => setBusinessEmail(e.target.value)}
                    value={businessEmail}
                />
                <label className="form-label" htmlFor="businessEmail">
                    Business Email address
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
            </div>
            <FormControl className="form-outline mb-4 flex-column d-flex">
                <InputLabel id="college-label">Select College(s)</InputLabel>
                <Select
                    labelId="college-label"
                    id="College"
                    name="College"
                    required
                    multiple
                    value={selectedColleges}
                    onChange={handleCollegeChange}
                    label="Select College(s)"
                >
                    <MenuItem value="" disabled>Select College(s)</MenuItem>
                    <MenuItem value="IIT Jodhpur">IIT Jodhpur</MenuItem>
                    <MenuItem value="NIFT Jodhpur">NIFT Jodhpur</MenuItem>
                    <MenuItem value="Ayurved University">Ayurved University Jodhpur</MenuItem>
                    <MenuItem value="NLU Jodhpur">NLU Jodhpur</MenuItem>
                    <MenuItem value="SPUP">Sardar Patel University of Police Jodhpur</MenuItem>
                    <MenuItem value="Agriculture University">Agriculture University</MenuItem>
                </Select>
                <label className="form-label" htmlFor="businessEmail">
                    Select the college(s) where your service is available
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
            </FormControl>

            <FormControl className="form-outline mb-4 flex-column d-flex">
                <InputLabel id="service-label">Select Mode(s) of Service Available</InputLabel>
                <Select
                    labelId="mode-label"
                    id="ModeOfDelivery"
                    name="ModeOfDelivery"
                    required
                    multiple
                    value={selectedModeOfDelivery}
                    onChange={handleModeOfDeliveryChange}
                    label="Select Mode(s) of Service Available"
                >
                    <MenuItem value="" disabled>Select Mode(s) of Service</MenuItem>
                    <MenuItem value="Dine-In">Dine-In</MenuItem>
                    <MenuItem value="Takeaway">Takeaway</MenuItem>
                    <MenuItem value="Delivery">Delivery</MenuItem>
                </Select>
                <label className="form-label" htmlFor="businessEmail">
                    Select Mode(s) of Service Available
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
            </FormControl>

            <FormControl className="form-outline mb-4 flex-column d-flex">
                <InputLabel id="service-label">Select Shop Category(s)</InputLabel>
                <Select
                    labelId="shopCategory-label"
                    id="ShopCategory"
                    name="ShopCategory"
                    required
                    multiple
                    value={selectedShopCategory}
                    onChange={handleShopCategoryChange}
                    label="Select Shop Category(s)"
                >
                    <MenuItem value="" disabled>Select Shop Category(s)</MenuItem>
                    <MenuItem value="Food">Food</MenuItem>
                    <MenuItem value="Grocery">Grocery</MenuItem>
                </Select>
                <label className="form-label" htmlFor="businessEmail">
                    Select Shop Category(s)
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
            </FormControl>

            <FormControl className="form-outline mb-4 flex-column d-flex">
                <InputLabel id="category-label">Select Product(s) Category</InputLabel>
                <Select
                    labelId="category-label"
                    id="ProductCategory"
                    name="ProductCategory"
                    required
                    multiple
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Select Product(s) Category"
                >
                    <MenuItem value="" disabled>Select Product(s) Category</MenuItem>
                    <MenuItem value="Italian">Italian</MenuItem>
                    <MenuItem value="Starters">Starters</MenuItem>
                    <MenuItem value="Chinese">Chinese</MenuItem>
                    <MenuItem value="Indian Main Course">Indian Main Course</MenuItem>
                    <MenuItem value="Rolls & Breads">Rolls & Breads</MenuItem>
                    <MenuItem value="South Indian">South Indian</MenuItem>
                    <MenuItem value="Snacks">Snacks</MenuItem>
                    <MenuItem value="Beverages">Beverages</MenuItem>
                    <MenuItem value="Kitchen & Household Essentials">Kitchen & Household Essentials</MenuItem>
                    <MenuItem value="Personal Care">Personal Care</MenuItem>
                    <MenuItem value="Bakery & Confectionary">Bakery & Confectionary</MenuItem>
                    <MenuItem value="Dairy & Eggs">Dairy & Eggs</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                </Select>
                <label className="form-label" htmlFor="businessEmail">
                    Select Product(s) Category
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
            </FormControl>

            <div className="form-outline mb-4">
                <input
                    type="password"
                    id="userPass"
                    className={`form-control ${passwordsMatch ? "" : "is-invalid"}`}
                    onChange={handlePasswordChange}
                    value={password}
                    required
                />
                <label className="form-label" htmlFor="userPass">
                    Password
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
                {!passwordsMatch && (
                    <div className="invalid-feedback">Passwords do not match.</div>
                )}
            </div>

            <div className="form-outline mb-4">
                <input
                    type="password"
                    id="confirmPass"
                    className={`form-control ${passwordsMatch ? "" : "is-invalid"}`}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                />
                <label className="form-label" htmlFor="confirmPass">
                    Confirm Password
                    <span
                        className="required-field"
                        style={{ color: "var(--color-primary)" }}
                    >
                        *
                    </span>
                </label>
            </div>

            <button
                type="submit"
                className="btn btn-primary btn-block mb-4"
                onClick={handleSubmit}
            >
                Sign up as Business
            </button>

            <div className="text-center">
                <p>
                    Already have an account?{" "}
                    <Link aria-current="page" to="/login">
                        Login
                    </Link>
                </p>
            </div>
        </>
    );
}
