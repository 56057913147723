import React, { useEffect, useState, useContext } from 'react';
import Loader from '../Loader/Loader';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './style.css';
import ShopDesp from './ShopDesp';
import { useParams } from 'react-router-dom';
import { db } from '../../Config/Config';
import './AddToCart'
import AddToCart from './AddToCart';
import { AuthContext } from '../Context/AuthContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Food() {
    const { currentUser } = useContext(AuthContext);
    const [shopInfo, setShopInfo] = useState(null);
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState({});
    const [selectedFilter, setSelectedFilter] = useState('*');
    const { shopId } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setCart({});
    }, [shopId]);

    useEffect(() => {
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);


    const handleInputChange = (shopId, productId, quantity) => {
        if (currentUser && currentUser.userType === 'user') {
            if (cart && Object.keys(cart).length > 0 && Object.keys(cart)[0] !== shopId) {
                setCart({ [shopId]: { [productId]: quantity } });
                toast.info('Your cart has been reset due to products from different shops.');
            }
            if (quantity > 0) {
                setCart((prevCart) => {
                    const updatedCart = prevCart[shopId] ? { ...prevCart } : { ...prevCart, [shopId]: {} };
                    updatedCart[shopId][productId] = quantity;
                    return updatedCart;
                });
            } else {
                setCart((prevCart) => {
                    if (!prevCart[shopId]) {
                        return prevCart;
                    }
                    const updatedCart = { ...prevCart, [shopId]: { ...prevCart[shopId] } };
                    delete updatedCart[shopId][productId];
                    if (Object.keys(updatedCart[shopId]).length === 0) {
                        delete updatedCart[shopId];
                    }
                    return updatedCart;
                });
            }
        } else {
            toast.info('Please log in as a user to add items to the cart.');
        }
    };


    const isShopClosed = shopInfo?.ShopStatus === 'Closed';
    const shopName = shopInfo?.BusinessName;

    useEffect(() => {
        setIsLoading(true);
        const shopRef = db.collection('businessUsers').doc(shopId);

        const unsubscribe = shopRef.onSnapshot((doc) => {
            if (doc.exists) {
                const shopData = doc.data();
                setShopInfo(shopData);
                const products = shopData.products || [];
                const productData = products.map((product) => ({
                    id: product.Id || '',
                    category: product.Category || '',
                    dietaryPreference: product.DietaryPreference || '',
                    name: product.Name || '',
                    price: product.Price || '',
                    status: product.status || '',
                    shopStatus: product.ShopStatus || '',
                    Customization: product.Customization || [],
                }));
                setProducts(productData);
            } else {
                setShopInfo(null);
                setProducts([]);
            }
            setIsLoading(false);
        });

        AOS.init({
            duration: 1000,
            delay: 100,
        });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [shopId]);

    const filteredProducts = selectedFilter === '*'
        ? products
        : products.filter((product) => product.category === selectedFilter);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            {shopInfo && <ShopDesp shop={shopInfo} />}
            <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                    <ul id="menu-flters">
                        <li
                            data-filter="*"
                            className={selectedFilter === '*' ? 'filter-active' : ''}
                            onClick={() => setSelectedFilter('*')}
                        >
                            All
                        </li>
                        {shopInfo &&
                            shopInfo.productCategory.map((category) => (
                                <li
                                    key={category}
                                    data-filter={`.filter-${category.toLowerCase().replace(/\s/g, '-')}`}
                                    className={selectedFilter === category ? 'filter-active' : ''}
                                    onClick={() => setSelectedFilter(category)}
                                >
                                    {category}
                                </li>
                            ))}
                    </ul>
                </div>
            </div>

            <div className="menu-items row" style={{ justifyContent: 'center' }}>
                {filteredProducts.map((product, index) => (
                    <div className="col-md-5 menu-item" key={product.id} style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} data-aos="zoom-in" data-aos-delay={20 * (index + 1)}>
                        <h3>{product.name}</h3>
                        <p>Price: ₹{product.price}</p>
                        <div className="dietary-preference">
                            {product.dietaryPreference === 'Veg' && (
                                <i className="fa-solid fa-circle" style={{ color: 'green' }} title="Veg"></i>
                            )}
                            {product.dietaryPreference === 'Non Veg' && (
                                <i className="fa-regular fa-square-caret-up" style={{ color: 'red' }} title="Non Veg"></i>
                            )}
                            {product.dietaryPreference === 'Egg' && (
                                <i className="fa-solid fa-egg" style={{ color: '#C7BC3C' }} title="Egg"></i>
                            )}
                        </div>
                        <AddToCart shopId={shopId} cart={cart} handleInputChange={handleInputChange} product={product} />
                    </div>
                ))}
            </div>
            {isShopClosed ? (
                <div className="shop-closed-overlay">
                    {shopName} is not accepting any orders at the moment.
                </div>
            ) : (
                <>
                </>
            )}
            {isLoading && <Loader />}
        </>
    );
}
