import firebase from 'firebase/compat/app';
import {GoogleAuthProvider} from "firebase/auth";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBFMftP_R0Tobqzkhxp0XNkSR9ymAL7TCE",
    authDomain: "deliverykartiitj.firebaseapp.com",
    projectId: "deliverykartiitj",
    storageBucket: "deliverykartiitj.appspot.com",
    messagingSenderId: "243265410242",
    appId: "1:243265410242:web:b57c7e35279a18bf1a975b",
    measurementId: "G-ZMKPMVP6Q3"
};

const initialize = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const provider = new GoogleAuthProvider();

export { initialize, auth, db, storage, provider }