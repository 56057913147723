import React, { useState, useEffect } from 'react';
import "./style.css";
import { doc, serverTimestamp, updateDoc, getDoc } from "firebase/firestore";
import { auth, db } from "../../Config/Config";
import { useNavigate } from "react-router-dom";
import Navbar from './Navbar';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

export default function AddNewProduct() {
    const [data, setData] = useState({
        PrdName: '',
        DietaryInfo: '',
        Category: '',
        Price: '',
        subtitle: '',
        packingFee: 0,
        Customizations: [],
    });

    const [isLoading, setIsLoading] = useState(false);
    const [availableCategories, setAvailableCategories] = useState([]);
    const navigate = useNavigate();

    const handleInput = (e) => {
        const id = e.target.id;
        const value = e.target.id === 'packingFee' ? parseFloat(e.target.value) : e.target.value;
        setData({ ...data, [id]: value });
    };

    const handleCategoryInput = (event) => {
        const selectedServices = event.target.value;
        setData({ ...data, Category: selectedServices });
    };

    const handleDietPrefInput = (event) => {
        const selectedServices = event.target.value;
        setData({ ...data, DietaryInfo: selectedServices });
    };

    const handleAddCustomization = () => {
        const newCustomization = {
            customizationName: '',
            customizationPrice: 0,
        };

        setData({
            ...data,
            Customizations: [...data.Customizations, newCustomization],
        });
    };

    const handleCustomizationType = (index, event) => {
        const selectedType = event.target.value;

        const updatedCustomizations = [...data.Customizations];
        updatedCustomizations[index] = {
            ...updatedCustomizations[index],
            customizationType: selectedType,
        };

        setData({
            ...data,
            Customizations: updatedCustomizations,
        });
    };

    const handleRemoveCustomization = (index) => {
        const customizations = [...data.Customizations];
        customizations.splice(index, 1);

        setData({
            ...data,
            Customizations: customizations,
        });
    };

    const handleCustomizationInput = (index, e) => {
        const { name, value } = e.target;
        const customizations = [...data.Customizations];

        const updatedValue = name === 'customizationPrice' ? parseFloat(value) : value;

        customizations[index] = {
            ...customizations[index],
            [name]: updatedValue,
        };

        setData({
            ...data,
            Customizations: customizations,
        });
    };

    function generateUniqueProductId() {
        let result = '';
        const characters = '0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 9; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    useEffect(() => {
        const currentUser = auth.currentUser;

        const fetchUserCategories = async () => {
            if (currentUser) {
                const uid = currentUser.uid;
                const userRef = doc(db, 'businessUsers', uid);

                try {
                    const userSnapshot = await getDoc(userRef);
                    const userData = userSnapshot.data() || {};
                    const userCategories = userData.productCategory || [];

                    setAvailableCategories(userCategories);
                } catch (err) {
                    console.error(err.message);
                }
            }
        };

        fetchUserCategories();
    }, []);

    const handleAdd = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const currentUser = auth.currentUser;

        if (currentUser) {
            const uid = currentUser.uid;
            const userRef = doc(db, 'businessUsers', uid);

            try {
                const userSnapshot = await getDoc(userRef);
                const userData = userSnapshot.data() || {};

                const products = userData.products || [];
                products.push({
                    Name: data.PrdName,
                    Category: data.Category,
                    Price: data.Price,
                    DietaryPreference: data.DietaryInfo,
                    Id: generateUniqueProductId(),
                    status: 'Available',
                    Customization: data.Customizations,
                    packingFee: data.packingFee,
                    subtitle: data.subtitle,
                });

                const foodCategories = userData.foodCategories || [];
                if (!foodCategories.includes(data.Category)) {
                    foodCategories.push(data.Category);
                }

                await updateDoc(userRef, {
                    ...userData,
                    products,
                    foodCategories,
                    timeStamp: serverTimestamp(),
                });

                setIsLoading(false);
                toast.success(
                    'Successfully Added the Product. You will now automatically get redirected to the Product page'
                );
                setTimeout(() => {
                    navigate('/business/product');
                }, 2500);
            } catch (err) {
                setIsLoading(false);
                toast.error(err.message);
            }
        }
    };

    return (
        <>
            <div className="home">
                <Navbar />
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                <div className="homeContainer">
                    <div className="container-fluid px-1 py-5 mx-auto">
                        <div className="row d-flex justify-content-center">
                            <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center" style={{ paddingTop: '20px', width: '62%' }}>
                                <div className="prdcard">
                                    <h1>Add New Products</h1>
                                    <form className="form-card" onSubmit={handleAdd}>
                                        <div className="row justify-content-between text-left">
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="PrdName" className="form-control-label px-3">
                                                    Product Name<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <TextField
                                                    type="text"
                                                    id="PrdName"
                                                    name="PrdName"
                                                    placeholder="Product name"
                                                    onChange={handleInput}
                                                    value={data.PrdName}
                                                    variant="outlined"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="PrdName" className="form-control-label px-3">
                                                    Product Subtitle
                                                </InputLabel>
                                                <TextField
                                                    type="text"
                                                    id="subtitle"
                                                    name="subtitle"
                                                    placeholder="Product Subtitle"
                                                    onChange={handleInput}
                                                    value={data.subtitle}
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-between text-left">
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="Category" className="form-control-label px-3">
                                                    Category<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="Category">Category</InputLabel>
                                                    <Select
                                                        id="Category"
                                                        name="Category"
                                                        onChange={handleCategoryInput}
                                                        value={data.Category}
                                                        label="Category"
                                                        required
                                                    >
                                                        <MenuItem value="" disabled>Select a Category</MenuItem>
                                                        {availableCategories.map(category => (
                                                            <MenuItem key={category} value={category}>{category}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="DietaryInfo" className="form-control-label px-3">
                                                    Dietary Preference<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="DietaryInfo">Dietary Preference</InputLabel>
                                                    <Select
                                                        id="DietaryInfo"
                                                        name="DietaryInfo"
                                                        onChange={handleDietPrefInput}
                                                        value={data.DietaryInfo}
                                                        label="Dietary Preference"
                                                        required
                                                    >
                                                        <MenuItem value="" disabled>Select Dietary Preference</MenuItem>
                                                        <MenuItem value="Veg">Vegetarian</MenuItem>
                                                        <MenuItem value="Non Veg">Non Vegetarian</MenuItem>
                                                        <MenuItem value="Egg">Eggetarian</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between text-left">
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="Price" className="form-control-label px-3">
                                                    Price<span className="text-danger"> *</span>
                                                </InputLabel>
                                                <TextField
                                                    type="number"
                                                    id="Price"
                                                    name="Price"
                                                    placeholder="Price"
                                                    onChange={handleInput}
                                                    value={data.Price}
                                                    variant="outlined"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-sm-6 flex-column d-flex mt-3">
                                                <InputLabel htmlFor="Price" className="form-control-label px-3">
                                                    Packing Charge
                                                </InputLabel>
                                                <TextField
                                                    type="number"
                                                    id="packingFee"
                                                    name="packingFee"
                                                    placeholder="Packing Charge"
                                                    onChange={handleInput}
                                                    value={data.packingFee}
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                        <div className="row text-left">
                                            {data.Customizations.map((customization, index) => (
                                                <div key={index}>
                                                    <div className="row">
                                                        <div className="form-group col-sm-4 flex-column d-flex mt-3">
                                                            <InputLabel htmlFor={`customizationName${index}`} className="form-control-label px-3">
                                                                Customization Name<span className="text-danger"> *</span>
                                                            </InputLabel>
                                                            <TextField
                                                                type="text"
                                                                id={`customizationName${index}`}
                                                                name="customizationName"
                                                                placeholder="Customization Name"
                                                                onChange={(e) => handleCustomizationInput(index, e)}
                                                                value={customization.customizationName}
                                                                variant="outlined"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group col-sm-4 flex-column d-flex mt-3">
                                                            <InputLabel htmlFor={`customizationPrice${index}`} className="form-control-label px-3">
                                                                Customization Price<span className="text-danger"> *</span>
                                                            </InputLabel>
                                                            <TextField
                                                                type="number"
                                                                id={`customizationPrice${index}`}
                                                                name="customizationPrice"
                                                                placeholder="Customization Price"
                                                                onChange={(e) => handleCustomizationInput(index, e)}
                                                                value={customization.customizationPrice}
                                                                variant="outlined"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group col-sm-4 flex-column d-flex mt-3">
                                                            <InputLabel htmlFor={`customizationType${index}`} className="form-control-label px-3">
                                                                Customization Type<span className="text-danger"> *</span>
                                                            </InputLabel>
                                                            <FormControl fullWidth variant="outlined">
                                                                <InputLabel id={`CustomizationTypeLabel${index}`}>Customization Type</InputLabel>
                                                                <Select
                                                                    labelId={`CustomizationTypeLabel${index}`}
                                                                    id={`customizationType${index}`}
                                                                    onChange={(e) => handleCustomizationType(index, e)}
                                                                    value={customization.customizationType}
                                                                    label="Customization Type"
                                                                    required
                                                                >
                                                                    <MenuItem value="" disabled>Select Customization Type</MenuItem>
                                                                    <MenuItem value={'Size'}>Size</MenuItem>
                                                                    <MenuItem value={'Crust'}>Crust</MenuItem>
                                                                    <MenuItem value={'Toppings'}>Toppings</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="form-group col-sm-4 d-flex align-items-end mt-1">
                                                            <button
                                                                type="button"
                                                                className="btn-block btn-danger"
                                                                onClick={() => handleRemoveCustomization(index)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {/* <div className="row justify-content-center">
                                            <div className="form-group col-sm-6">
                                                <button type="button" className="btn-block btn-info" onClick={handleAddCustomization}>
                                                    Add Customization
                                                </button>
                                            </div>
                                        </div> */}
                                        <div className="row justify-content-center mt-5">
                                            <div className="form-group col-sm-6"> <button type="submit" className="btn-block btn-primary">Submit</button> </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
        </>
    )
}
