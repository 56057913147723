import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from "../../Config/Config";
import Loader from '../Loader/Loader';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function OrderHistory() {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [orderRatings, setOrderRatings] = useState({});
    const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribeAuthStateChange = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const uid = user.uid;
                const userRef = db.collection('users').doc(uid);

                const unsubscribe = userRef.onSnapshot(async (doc) => {
                    if (doc.exists) {
                        setIsLoading(true);

                        const ordersID = doc.data().ordersID || [];
                        const promises = ordersID.map((orderID) => {
                            const orderRef = db.collection('Orders').doc(orderID);
                            return orderRef.onSnapshot((orderDoc) => {
                                if (orderDoc.exists) {
                                    const orderData = orderDoc.data();
                                    const shopRef = db.collection('businessUsers').doc(orderData.shopId);
                                    return shopRef.onSnapshot((shopDoc) => {
                                        if (shopDoc.exists) {
                                            const updatedOrderData = {
                                                orderId: orderID,
                                                shop: shopDoc.data(),
                                                ...orderData
                                            };
                                            setOrders(prevOrders => [updatedOrderData, ...prevOrders]);
                                        }
                                    });
                                }
                            });
                        });

                        await Promise.all(promises);
                        setOrders(prevOrders => prevOrders.sort((a, b) => b.timestamp - a.timestamp));

                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                }, (error) => {
                    toast.error("Error Fetching Orders History. Please try after sometime!!");
                    setIsLoading(false);
                });

                AOS.init({
                    duration: 1000,
                    delay: 70,
                });

                return () => {
                    if (unsubscribe) {
                        unsubscribe();
                    }
                };
            } else {
                setIsLoading(false);
            }
        });

        return () => {
            unsubscribeAuthStateChange();
        };
    }, []);

    const handleReorder = async (order) => {
        const updatedCart = {};
        if (!updatedCart[order.shopId]) {
            updatedCart[order.shopId] = {};
        }
        order.products.forEach((product) => {
            updatedCart[order.shopId][product.productId] = product.quantity;
        });
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        navigate('/cart');
    };

    const handleRatingClick = async (orderId) => {
        try {
            const { foodRating } = orderRatings[orderId] || { foodRating: 0 };

            if (foodRating > 0) {
                const order = orders.find((o) => o.orderId === orderId);


                await db.collection('Orders').doc(orderId).update({
                    rating: foodRating,
                });

                const shopId = order.shopId;
                const shopDoc = await db.collection('businessUsers').doc(shopId).get();
                const shopData = shopDoc.data();

                const newTotalRatings = (shopData.noOfRatings || 0) + 1;
                const newOverallRating =
                    ((shopData.overallRating || 0) * (shopData.noOfRatings || 0) + foodRating) / newTotalRatings;

                await db.collection('businessUsers').doc(shopId).update({
                    overallRating: newOverallRating,
                    noOfRatings: newTotalRatings,
                });

                setIsReviewModalVisible(false);
                toast.success('Food rating saved successfully!');
            } else {
                toast.warn('Please select a food rating before submitting.');
            }
        } catch (error) {
            setIsReviewModalVisible(false);
            toast.error('Error saving food rating. Please try again.');
        }
    };

    return (
        <>
            {orders.map((order, index) => (
                <>
                    <div className="modal fade" id={`invoiceModal${index}`} tabIndex="-1" aria-labelledby={`invoiceModalLabel${index}`} aria-hidden="true" key={`invoice${order.orderId}`}>
                        <div className="modal-dialog">
                            <div className="modal-content" style={{ borderRadius: '15px', borderTop: '3px solid #f37a27', borderBottom: '3px solid #f37a27' }}>
                                <div className="modal-header">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body text-start text-black p-4">
                                    <h5 className="modal-title text-uppercase text-center mb-4" id="invoiceModalLabel" style={{ color: "#f37a27", fontWeight: 'bold' }}>Purchase Reciept</h5>
                                    <div className="row">
                                        <div className="col mb-1">
                                            <p className="small text-muted mb-1">Shop Name</p>
                                            <p>{order.shop.BusinessName}</p>
                                        </div>
                                        <div className="col mb-1">
                                            <p className="small text-muted mb-1">Order No.</p>
                                            <p>{order.orderId}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {order.OrderType === 'Delivery' ? (<div className="col mb-1">
                                            <p className="small text-muted mb-1">Delivery Address</p>
                                            <p>{order.userAddress}</p>
                                        </div>) : (<div className="col mb-1">
                                            <p className="small text-muted mb-1">Order Type</p>
                                            <p>{order.OrderType}</p>
                                        </div>)}
                                        <div className="col mb-1">
                                            <p className="small text-muted mb-1">Phone Number</p>
                                            <p>+91 {order.userPhone}</p>
                                        </div>
                                    </div>
                                    <h4 className="mb-4" style={{ color: "#35558a" }}>Thanks for your order</h4>
                                    <h5 className="mb-0" style={{ color: "#35558a" }}>Payment Summary</h5>
                                    <hr className="mt-2 mb-4" style={{ height: "0", backgroundColor: "transparent", opacity: ".75", bordertop: "2px dashed #9e9e9e" }} />
                                    <div>
                                        {order.products && order.products.map(product => (
                                            <div className="d-flex justify-content-between" key={product.productId}>
                                                <p className="mb-0">{product.name} (Qty: {product.quantity})</p>
                                                <p className="mb-0">&#8377;{product.price * product.quantity}</p>
                                            </div>
                                        ))}
                                    </div>

                                    {order.OrderType === 'Delivery' ? (
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">Delivery Charges</p>
                                            <p className="mb-0">&#8377;{order.deliveryCharge}</p>
                                        </div>) : (
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">Convenience Fee</p>
                                            <p className="mb-0">&#8377;{order.convenienceCharge}</p>
                                        </div>)}

                                    <div className="d-flex justify-content-between pb-1">
                                        <p>Tax</p>
                                        <p>&#8377; 0</p>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <h5 className="fw-bold">Total Paid</h5>
                                        <p className="lead fw-bold mb-0" style={{ color: "#f37a27" }}>&#8377;{order.totalAmount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className={`modal ${isReviewModalVisible ? 'show' : ''}`} id={`reviewModal${index}`} tabIndex="-1" aria-labelledby={`reviewModalLabel${index}`} aria-hidden={!isReviewModalVisible} key={`review${order.orderId}`}>
                        <div className="modal-dialog">
                            <div className="modal-content" style={{ borderRadius: '15px', borderTop: '3px solid #f37a27', borderBottom: '3px solid #f37a27' }}>
                                <div className="modal-header">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body text-start text-black p-4">
                                    <h5 className="modal-title text-uppercase text-center mb-4" id={`reviewModalLabel${index}`} style={{ color: "#f37a27", fontWeight: 'bold' }}>FEEDBACK</h5>
                                    <div className="row">
                                        <div className="col mb-1">
                                            <p className="small text-muted mb-1">Shop Name</p>
                                            <p>{order.shop.BusinessName}</p>
                                        </div>
                                        <div className="col mb-1">
                                            <p className="small text-muted mb-1">Order No.</p>
                                            <p>{order.orderId}</p>
                                        </div>
                                    </div>
                                    <p className="mb-2" style={{ color: "#35558a" }}>Your latest order</p>
                                    <div>
                                        {order.products && order.products.map(product => (
                                            <div className="d-flex justify-content-between" key={product.productId}>
                                                <p className="small mb-0">{product.name} (Qty: {product.quantity})</p>
                                                <p className="small mb-0">&#8377;{product.price * product.quantity}</p>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <p className="small mb-0">Shipping</p>
                                        <p className="small mb-0">&#8377;{order.deliveryCharge}</p>
                                    </div>

                                    <div className="d-flex justify-content-between pb-1">
                                        <p className="small">Tax</p>
                                        <p className="small">&#8377; 0</p>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <p className="fw-bold">Total Paid</p>
                                        <p className="lead fw-bold mb-0" style={{ color: "#f37a27" }}>&#8377;{order.totalAmount}</p>
                                    </div>
                                    <h4 className="mb-3" style={{ color: "#35558a" }}>Please rate your order</h4>

                                    <div className="mb-3">
                                        <div className="rating-wrapper">
                                            {[5, 4, 3, 2, 1].map((ratingValue) => (
                                                <React.Fragment key={`food-${ratingValue}`}>
                                                    <input
                                                        type="radio"
                                                        id={`food-${ratingValue}-star-rating-${order.orderId}`}
                                                        name={`food-star-rating-${order.orderId}`}
                                                        value={ratingValue}
                                                        checked={ratingValue === (orderRatings[order.orderId]?.foodRating || 0)}
                                                        onChange={() => {
                                                            setOrderRatings((prevRatings) => ({
                                                                ...prevRatings,
                                                                [order.orderId]: {
                                                                    ...prevRatings[order.orderId],
                                                                    foodRating: ratingValue,
                                                                },
                                                            }));
                                                        }}
                                                    />

                                                    <label
                                                        htmlFor={`food-${ratingValue}-star-rating-${order.orderId}`}
                                                        className={`star-rating ${ratingValue === (orderRatings[order.orderId]?.foodRating || 0) ? 'selected' : ''}`}
                                                    >
                                                        <i className="fas fa-star d-inline-block"></i>
                                                    </label>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            handleRatingClick(order.orderId);
                                        }}
                                    >
                                        Submit Rating
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ))}
            <div className="container" style={{ marginTop: '100px', paddingBottom: '100px' }}>
                <ToastContainer
                    position="top-right"
                    autoClose={2400}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" />
                {isLoading && <Loader />}
                <div className="row">
                    <div className="col-md-12">
                        <div className="osahan-account-page-right shadow-sm bg-white p-4 h-100">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                                    <h2 className="font-weight-bold mt-0 mb-4">Past Orders</h2>
                                    {!isLoading && orders.length === 0 && <p>No past orders found.</p>}
                                    {!isLoading && orders.length > 0 && (
                                        orders.map((order, index) => (
                                            <div className="bg-white card mb-4 order-list shadow-sm" style={{ borderRadius: '20px' }} key={index} data-aos="zoom-in" data-aos-delay={20 * (index + 1)}>
                                                <div className="p-4">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            {order.status !== '' && order.status !== 'Delivered' && order.status !== 'Cancelled' && (
                                                                <i className="d-flex justify-content-end text-dark">Estimated Delivery Time: {order.DeliveryTime} minutes</i>
                                                            )}
                                                            <i className="d-flex justify-content-end text-dark">Order Placed on {new Intl.DateTimeFormat('en-US', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                second: '2-digit',
                                                                hour12: true
                                                            }).format(new Date(order.timestamp.seconds * 1000))}</i>
                                                            {order.updatedAt && (<i className="d-flex justify-content-end text-dark">{order.status} on {new Intl.DateTimeFormat('en-US', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                second: '2-digit',
                                                                hour12: true
                                                            }).format(new Date(order.updatedAt.seconds * 1000))}</i>
                                                            )}
                                                            <h4 className="mb-2">
                                                                <Link aria-current="page" to={`/${order.shopId}`}>
                                                                    {order.shop.BusinessName}
                                                                </Link>
                                                            </h4>
                                                            <div className="d-flex flex-column align-items-start mb-3">
                                                                <div className="d-flex align-items-center mb-1">
                                                                    <i className="icofont-location-arrow me-2 text-secondary">+91 {order.shop.Phone}</i>
                                                                </div>
                                                                <div className="d-flex justify-content-between mb-1">
                                                                    <i className="icofont-list me-2 text-success">ORDER #{order.orderId}</i>
                                                                </div>
                                                                <p className="text-dark mb-0">
                                                                    {order.products && order.products.map(product => (
                                                                        <span key={product.productId}>
                                                                            <span className="text-dark">{product.name}</span> x <span className="text-secondary">{product.quantity}</span>{' '}
                                                                        </span>
                                                                    ))}
                                                                </p>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="text-primary font-weight-bold mt-1">Total Paid: &#8377;{order.totalAmount}</p>
                                                                <div className="d-flex justify-content-end">
                                                                    <Link to="/contact" className="btn btn-sm btn-outline-primary me-2" ><i className="icofont-headphone-alt me-2"></i> HELP</Link>
                                                                    <Link to="/cart" className="btn btn-sm btn-primary" onClick={() => handleReorder(order)}><i className="icofont-refresh me-2"></i> REORDER</Link>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                {order.status === 'Delivered' && (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-light btn-lg"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target={`#invoiceModal${index}`}
                                                                        >
                                                                            <i className="fas fa-info me-2 "></i> Get Invoice
                                                                        </button>
                                                                        {order.status === 'Delivered' && order.rating ? (
                                                                            <div className="text-center">
                                                                                <div className="rating-wrapper" style={{ padding: '2px 10px', marginTop: '12px' }}>
                                                                                    {[...Array(order.rating).keys()].map((i) => (
                                                                                        <label key={i} className="star-rating selected" >
                                                                                            <i className="fas fa-star d-inline-block" style={{ fontSize: '14px' }}></i>
                                                                                        </label>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        ) : order.status === 'Delivered' ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary btn-lg"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target={`#reviewModal${index}`}
                                                                            >
                                                                                <i className="fas fa-info me-2"></i> Feedback
                                                                            </button>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
