import React, { useContext } from 'react'
import "./sidebar.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { auth } from '../../../Config/Config';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


export default function Sidebar() {
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        auth.signOut().then(() => {
            dispatch({ type: "LOGOUT" });
            navigate("/adminLogin");
        });
    };
    return (
        <>
            <div className="sidebar">
                <div className="sidebar-top">
                    <Link to="/admin" style={{ textDecoration: "none" }}>
                        <span className="sidebar-logo">DKartAdmin</span>
                    </Link>
                </div>
                <hr className="sidebar-hr" />
                <div className="sidebar-centerbar">
                    <ul>
                        <p className="sidebar-title">MAIN</p>
                        <Link to="/admin" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <DashboardIcon className="sidebar-icon" />
                                <span className="sidebar-text">Dashboard</span>
                            </li>
                        </Link>
                        <p className="sidebar-title">LISTS</p>
                        <Link to="/admin/businessUsers" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <GroupIcon className="sidebar-icon" />
                                <span className="sidebar-text">Business Users</span>
                            </li>
                        </Link>
                        <Link to="/admin/userQuery" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <LiveHelpIcon className="sidebar-icon" />
                                <span className="sidebar-text">User Query</span>
                            </li>
                        </Link>
                        <Link to="/admin/businessQuery" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <QuestionAnswerIcon className="sidebar-icon" />
                                <span className="sidebar-text">Business Query</span>
                            </li>
                        </Link>
                        <Link to="/admin/OrderDetails" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <CurrencyRupeeIcon className="sidebar-icon" />
                                <span className="sidebar-text">Transactions</span>
                            </li>
                        </Link>
                        <p className="sidebar-title">USER</p>
                        <li className="sidebar-item">
                            <AccountCircleOutlinedIcon className="sidebar-icon" />
                            <span className="sidebar-text">Profile</span>
                        </li>
                        <li className="sidebar-item" onClick={handleLogout}>
                            <ExitToAppIcon className="sidebar-icon" />
                            <span className="sidebar-text">Logout</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
