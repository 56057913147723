import React, { useContext, useState, useEffect, useCallback } from 'react'
import "../Admin/Sidebar/sidebar.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import Switch from '@mui/material/Switch';
import { auth, db } from '../../Config/Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';


export default function Navbar() {

    const [shopStatus, setShopStatus] = useState();
    const [deliveryStatus, setDeliveryStatus] = useState();
    const [shopData, setShopData] = useState();
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const checkAdminUser = async () => {
        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            if (Array.isArray(shopData.manageUsers)) {
                const isAdmin = shopData.manageUsers.some(user => user.uid === userId);
                return isAdmin;
            }
        }
        return false;
    };


    const handleShopStatusChange = useCallback(async () => {
        try {
            const updatedStatus = !shopStatus;
            setShopStatus(updatedStatus);

            const currentUser = auth.currentUser;
            if (currentUser) {
                const userId = currentUser.uid;
                const userDocRef = db.collection('businessUsers').doc(userId);
                await userDocRef.set(
                    { ShopStatus: updatedStatus ? 'Open' : 'Closed' },
                    { merge: true }
                );

                if (!updatedStatus) {
                    const updatedDeliveryStatus = 'Closed';
                    setDeliveryStatus(updatedDeliveryStatus);
                    await userDocRef.set(
                        { DeliveryStatus: updatedDeliveryStatus },
                        { merge: true }
                    );
                    toast.success(`Delivery status updated successfully to ${updatedDeliveryStatus}`);
                }

                toast.success(`Shop status updated successfully to ${updatedStatus ? 'Open' : 'Closed'}`);
            } else {
                toast.info('No user is currently logged in.');
            }
        } catch (error) {
            toast.error('Error updating shop status. Please try again later.');
        }
    }, [shopStatus]);

    const handleDeliveryStatusChange = useCallback(async () => {
        try {
            const updatedStatus = !deliveryStatus;
            setDeliveryStatus(updatedStatus);

            const currentUser = auth.currentUser;
            if (currentUser) {
                const userId = currentUser.uid;
                const userDocRef = db.collection('businessUsers').doc(userId);

                await userDocRef.set(
                    { DeliveryStatus: updatedStatus ? 'Open' : 'Closed' },
                    { merge: true }
                );

                toast.success(`Delivery status updated successfully to ${updatedStatus ? 'Open' : 'Closed'}`);
            } else {
                toast.info('No user is currently logged in.');
            }
        } catch (error) {
            toast.error('Error updating delivery status. Please try again later.');
        }
    }, [deliveryStatus]);

    useEffect(() => {
        const fetchShopData = async () => {
            try {
                auth.onAuthStateChanged(async (user) => {
                    if (user) {
                        const userId = user.uid;
                        const userDocRef = db.collection('businessUsers').doc(userId);
                        const userDoc = await userDocRef.get();

                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setShopData(userData);
                        } else {
                            toast.error('Shop data not found.');
                        }
                    }
                });
            } catch (error) {
                toast.error('Error fetching shop data. Please Try After SomeTime!!');
            }
        };

        fetchShopData();
    }, []);


    useEffect(() => {
        const fetchShopStatus = async () => {
            try {
                auth.onAuthStateChanged(async (user) => {
                    if (user) {
                        const userId = user.uid;
                        const userDocRef = db.collection('businessUsers').doc(userId);
                        const userDoc = await userDocRef.get();

                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            const initialShopStatus = userData.ShopStatus === 'Open';
                            const initialDeliveryStatus = userData.DeliveryStatus === 'Open';
                            setShopStatus(initialShopStatus);
                            setDeliveryStatus(initialDeliveryStatus);
                        }
                    }
                });
            } catch (error) {
                toast.error('Error fetching shop status. Please Try After SomeTime!!');
            }
        };

        fetchShopStatus();
    }, [handleShopStatusChange]);

    useEffect(() => {
        const updateDeliveryStatus = async () => {
            try {
                const currentUser = auth.currentUser;
                if (currentUser) {
                    const userId = currentUser.uid;
                    const userDocRef = db.collection('businessUsers').doc(userId);
                    const userDoc = await userDocRef.get();

                    if (userDoc.exists) {
                        const initialDeliveryStatus = userDoc.data().DeliveryStatus === 'Open';
                        setDeliveryStatus(initialDeliveryStatus);
                    }
                }
            } catch (error) {
                toast.error('Error fetching delivery status. Please try again later.');
            }
        };

        updateDeliveryStatus();
    }, [shopStatus]);


    const handleLogout = () => {
        auth.signOut().then(() => {
            dispatch({ type: "LOGOUT" });
            navigate("/login");
        });
    };
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2400}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <div className="sidebar">
                {shopData && (
                    <div className="sidebar-shop-info">
                        <span className="sidebar-shop-name">{shopData.BusinessName}</span>
                        <div className="sidebar-shop-rating">
                            <div className="star-rating" style={{ color: '#ffac00' }}>
                                {Array.from({ length: 5 }).map((_, index) => (
                                    <i
                                        key={index}
                                        className={`fas fa-star${index < Math.floor(shopData.overallRating) ? '' : '-half-alt'}`}
                                    ></i>
                                ))}
                            </div>
                            <span className="sidebar-rating-count">{shopData.noOfRatings || 0} Dining Reviews</span>
                        </div>
                    </div>
                )}
                <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} checked={shopStatus}
                        onChange={() => {
                            setShopStatus(!shopStatus);
                            handleShopStatusChange(shopStatus);
                        }} />}
                    label="Shop Status"
                    classes={{ label: 'sidebar-text' }}
                    style={{ paddingLeft: '28px' }} />
                <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} checked={deliveryStatus}
                        onChange={() => {
                            setDeliveryStatus(!deliveryStatus);
                            handleDeliveryStatusChange(deliveryStatus);
                        }} />}
                    label="Delivery Status"
                    classes={{ label: 'sidebar-text' }}
                    style={{ paddingLeft: '28px' }} />
                <div className="sidebar-centerbar">
                    <ul>
                        <p className="sidebar-title">MAIN</p>
                        <Link to="/business" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <DashboardIcon className="sidebar-icon" />
                                <span className="sidebar-text">Dashboard</span>
                            </li>
                        </Link>
                        <p className="sidebar-title">LISTS</p>
                        <Link to="/business/product" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <StoreIcon className="sidebar-icon" />
                                <span className="sidebar-text">Products</span>
                            </li>
                        </Link>
                        <Link to="/business/order" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <LocalShippingIcon className="sidebar-icon" />
                                <span className="sidebar-text">Orders</span>
                            </li>
                        </Link>
                        <Link to="/business/transaction" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <CurrencyRupeeIcon className="sidebar-icon" />
                                <span className="sidebar-text">Transactions</span>
                            </li>
                        </Link>

                        {shopData && shopData.isPremium && checkAdminUser() ? (
                            <Link to="/business/adminRefund" style={{ textDecoration: "none" }}>
                                <li className="sidebar-item">
                                    <CurrencyExchangeIcon className="sidebar-icon" />
                                    <span className="sidebar-text">Refunds</span>
                                </li>
                            </Link>
                        ) : null}

                        <Link to="/business/contactAdmin" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <ContactSupportIcon className="sidebar-icon" />
                                <span className="sidebar-text">Contact Admin</span>
                            </li>
                        </Link>
                        <p className="sidebar-title">USER</p>
                        <Link to="/business/profile" style={{ textDecoration: "none" }}>
                            <li className="sidebar-item">
                                <AccountCircleOutlinedIcon className="sidebar-icon" />
                                <span className="sidebar-text">Profile</span>
                            </li>
                        </Link>
                        {shopData && shopData.isPremium ? (
                            <Link to="/business/manageUsers" style={{ textDecoration: "none" }}>
                                <li className="sidebar-item">
                                    <SupervisedUserCircleIcon className="sidebar-icon" />
                                    <span className="sidebar-text">Manage Users</span>
                                </li>
                            </Link>
                        ) : null}

                        <Link aria-current="page" onClick={handleLogout}>
                            <li className="sidebar-item">
                                <ExitToAppIcon className="sidebar-icon" />
                                <span className="sidebar-text">Logout</span>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
        </>
    )
}
