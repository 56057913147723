import React from 'react';
import Navbar from './Navbar'
import ActiveOrder from './ActiveOrder';

export default function Orders() {

    return (
        <>
            <div className="home">
                <Navbar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <ActiveOrder />
                    </div>
                </div>
            </div>
        </>
    )
}
