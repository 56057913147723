import React, { useState } from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../Config/Config';
import { ToastContainer, toast } from 'react-toastify';

const IntroDetailPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        Phone: '',
        Campus: '',
        Address: '',
    });

    const handleCampusChange = (event) => {
        const selectedServices = event.target.value;
        setFormData({ ...formData, Campus: selectedServices });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateFields()) {
            setIsLoading(true);
            const currentUser = auth.currentUser;

            if (currentUser) {
                const uid = currentUser.uid;
                const userRef = doc(db, 'users', uid);

                try {
                    await updateDoc(userRef, {
                        ...formData,
                        userType: 'user',
                        timeStamp: serverTimestamp(),
                    });
                    toast.success('Form submitted Successfully! You will now automatically get redirected to the Home page');
                    setTimeout(() => {
                        navigate('/');
                    }, 2200);
                } catch (err) {
                    toast.error(err.message);
                }
            }
            setIsLoading(false);
        } else {
            toast.error('Please fill in all fields before submitting.');
        }
    };

    const validateFields = () => {
        return Object.values(formData).every((value) => value.trim() !== '');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const renderForm = () => {
        return (
            <fieldset>
                <h2 className="fs-title">Personal Details</h2>
                <h3 className="fs-subtitle">Tell us something more about you</h3>
                <div className="form-outline mt-5 mb-5 flex-column d-flex">
                    <input type="tel" name="Phone" placeholder="Phone" value={formData.Phone} onChange={handleChange} />
                    <input type="text" name="Address" placeholder="Address" value={formData.Address} onChange={handleChange} />
                    <select
                        id="College"
                        name="Campus"
                        required
                        value={formData.Campus}
                        onChange={handleCampusChange}
                        className="form-select"
                        style={{ height: '60px', color: 'gray', width: '100%' }}
                    >
                        <option value="" disabled>
                            Select Campus
                        </option>
                        <option value="IIT Jodhpur">IIT Jodhpur</option>
                        <option value="NIFT Jodhpur">NIFT Jodhpur</option>
                        <option value="Ayurved University">Ayurved University Jodhpur</option>
                        <option value="NLU Jodhpur">NLU Jodhpur</option>
                        <option value="SPUP">Sardar Patel University of Police Jodhpur</option>
                        <option value="Agriculture University">Agriculture University</option>
                    </select>
                </div>
                <button type="button" className="submit action-button" onClick={handleSubmit}>
                    Submit
                </button>
            </fieldset>
        );
    };

    return (
        <>
            {isLoading && <Loader />}
            <ToastContainer
                position="top-right"
                autoClose={2400}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container" style={{ height: '77vh', backgroundColor: '#6441A5' }}>
                <div className="row justify-content-center mt-5">
                    <div className="col-md-8 col-md-offset-3 mt-5">
                        <form id="msform">{renderForm()}</form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntroDetailPage;
