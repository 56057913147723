import React, { useEffect, useState } from 'react';
import { db, auth } from '../../Config/Config';
import { animated } from '@react-spring/web';
import { useSpring } from '@react-spring/core';
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import './ShopList.css';

export default function ShopList() {
    const currentUser = auth.currentUser;
    const [isLoading, setIsLoading] = useState(true);
    const [shops, setShops] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const userId = currentUser ? currentUser.uid : null;
                let campus = null;

                if (userId) {
                    const userRef = doc(db, 'users', userId);
                    try {
                        const docSnapshot = await getDoc(userRef);
                        if (docSnapshot.exists()) {
                            const { Campus } = docSnapshot.data();
                            campus = Campus;
                        }
                    } catch (error) {
                        toast.error(error.message);
                    }
                }

                const querySnapshot = await db.collection('businessUsers').where('UserStatus', '==', 'Verified').get();

                const shopsDataPromises = querySnapshot.docs.map(async (doc) => {
                    const data = doc.data();
                    const { BusinessName, ServiceAvailable } = data;

                    if (userId && ServiceAvailable && ServiceAvailable.includes(campus)) {
                        return {
                            id: doc.id,
                            ...data,
                        };
                    } else if (!userId) {
                        return {
                            id: doc.id,
                            ...data,
                        };
                    }

                    return null;
                });

                const shopsData = await Promise.all(shopsDataPromises);

                const filteredShopsData = shopsData.filter((shop) => shop !== null);

                setShops(filteredShopsData);
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [currentUser]);

    const shopCardSpring = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: { duration: 500 },
    });

    return (
        <>
            {/* <div className="breadcrumbs" data-aos="fade-up">
                {isLoading && <Loader />}
                <div className="container">
                    <h2>Our Services</h2>
                </div>
            </div> */}
            <div className="shop-list-container-service" data-aos="fade-up" data-aos-delay="150">
                {isLoading && <Loader />}
                {shops.map((shop, index) => (
                    <animated.div key={shop.id} style={shopCardSpring}>
                        <animated.div
                            className={`card-service shop-card-service ${shop.ShopStatus === 'Closed' ? 'closed' : ''}`}
                            style={{
                                opacity: shopCardSpring.opacity,
                            }}
                        >
                            <div className="animated-background"></div>
                            <div className="card-body-service">
                                <Link to={`/${shop.id}`}>
                                    <h5 className="card-title-service">{shop.BusinessName}</h5>
                                </Link>
                                {shop.overallRating && (
                                    <div className="star-rating-service" style={{ color: '#ffac00' }}>
                                        {[...Array(5)].map((_, index) => {
                                            const starClass =
                                                index < Math.floor(shop.overallRating)
                                                    ? 'fas fa-star'
                                                    : index - 0.5 < shop.overallRating
                                                        ? 'fas fa-star-half-alt'
                                                        : 'far fa-star';

                                            return <i key={index} className={starClass}></i>;
                                        })}
                                    </div>
                                )}
                                {shop.ShopStatus === 'Closed' && <p className="closed-message">Shop is currently closed</p>}
                            </div>
                        </animated.div>
                    </animated.div>
                ))}
            </div>
        </>
    );
}
